import { Fill, Icon, Stroke, Style, Text, RegularShape } from "ol/style";
import { Point } from "ol/geom";
import { NM_TO_M } from "../../../../utils/GeomUtils";
import { createPolygonFromCircle } from "./geomarker";
import { getSegmentsStyles } from "./draw";
import { isBackgroundDark } from "../../../../config/map";
import { store } from "../../../../app/store";
import { FONT_SIZE } from "./geomarker";

export function getPointSVG(fill: string, stroke: string) {
    return(
        '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300" height="300" viewBox="0 0 300 300">' +
        '<g fill="' +
        fill +
        '" stroke="' +
        stroke +
        '">' +
        '<path style="stroke-width:10" d="M 273.85615,138.49849 C 268.339,78.863576 220.6814,31.259676 161.00957,25.853269 V 2.6402855 H 138.33354 V 25.856625 C 78.65835,31.259676 31.000755,78.866932 25.490314,138.49849 H 2.2337034 v 22.67604 H 25.453399 c 5.309085,59.86312 53.05729,107.6818 112.880141,113.09827 v 23.21634 h 22.67603 V 274.2728 c 59.82285,-5.41312 107.57106,-53.2318 112.88014,-113.09827 h 23.19285 V 138.49849 Z M 161.00957,251.46924 v -22.0082 h -22.67603 v 22.0082 C 91.021604,246.21385 53.384824,208.50995 48.236823,161.17453 H 70.271875 V 138.49849 H 48.293874 C 53.633163,91.377847 91.172621,53.89544 138.33018,48.656829 v 22.02834 h 22.67268 v -22.02834 c 47.16092,5.235255 84.70038,42.717662 90.03966,89.841661 h -22.00149 v 22.67604 h 22.05854 c -5.14128,47.33542 -42.78142,85.03932 -90.09,90.29471 z M 149.67323,115.82581 c -18.77644,0 -34.01237,15.21245 -34.01237,33.99895 0,18.78987 15.23593,34.0258 34.01237,34.0258 18.77644,0 34.00902,-15.23593 34.00902,-34.0258 0,-18.7865 -15.23929,-33.99895 -34.00902,-33.99895 z"/>' +
        "</g></svg>"
    );
};

export default function editStyle(feature: any, _resolution: any, measures: boolean) {

    const fontSize = store.getState().nav.geomarkers.fontSize;

    const geometry = feature.getGeometry();
    const type = geometry.getType();
    const coordinates = geometry.getCoordinates();
    const label = feature.get("label");
    const radius = feature.get("radius");
    const beingEdited = feature.get("beingEdited");
    const color = feature.get("color") ? feature.get("color") : "white";
    const font = `bold ${FONT_SIZE[fontSize]}px sans-serif`;
    const width = 2;
    const outColor =
        isBackgroundDark()
            ? "rgba(255, 255, 255, 0.2)"
            : "rgba(0, 0, 0, 0.8)";

    const circleGeometry = radius ? createPolygonFromCircle(coordinates, radius * NM_TO_M) : null;
    const circleGeometryCentre = radius ? coordinates : null;

    const pointSVG = getPointSVG(color, outColor);

    switch (type) {
        case 'Point':
            if (radius) {
                return (
                    [
                        new Style({
                            geometry: circleGeometry,
                            stroke: new Stroke({
                                color: outColor,
                                width: width + 2,
                                lineDash: beingEdited ? [4, 8] : undefined
                            })
                        }),
                        new Style({
                            geometry: circleGeometry,
                            stroke: new Stroke({
                                color: color,
                                width: width,
                                lineDash: beingEdited ? [4, 8] : undefined
                            }),
                            text: new Text({
                                text: label,
                                font: font,
                                textBaseline: "top",
                                offsetY: 2,
                                fill: new Fill({ color: color }),
                                stroke: new Stroke({
                                    color: "black",
                                    width: 1,
                                }),
                            })
                        }),
                        new Style({
                            geometry: new Point(circleGeometryCentre),
                            image: new RegularShape({
                                fill: new Fill({ color }),
                                stroke: new Stroke({color, width: 2}),
                                points: 4,
                                radius: 4,
                                radius2: 0,
                                angle: Math.PI / 4,
                              })
                        }),
                        ...(measures ? [new Style({
                            text: new Text({
                                text: `radius: ${radius} nm`,
                                font: `${FONT_SIZE[fontSize] + 4}px Calibri,sans-serif`,
                                fill: new Fill({
                                    color: 'rgba(255, 255, 255, 1)',
                                }),
                                backgroundFill: new Fill({
                                    color: 'rgba(0, 0, 0, 0.4)',
                                }),
                                padding: [2, 2, 2, 2],
                                textBaseline: 'bottom',
                                offsetY: -1 * FONT_SIZE[fontSize] - 2,
                            }),
                            image: new RegularShape({
                                radius: 6,
                                points: 3,
                                angle: Math.PI,
                                displacement: [0, 8],
                                fill: new Fill({
                                    color: 'rgba(0, 0, 0, 0.4)',
                                }),
                            }),
                        })] : [])
                    ]
                );
            } else {
                return (
                    [
                        new Style({
                            image: new Icon({
                                opacity: 1,
                                src:
                                    "data:image/svg+xml;charset=utf-8," +
                                    encodeURIComponent(pointSVG),
                                scale: 0.03 * width
                            }),
                            text: new Text({
                                text: label,
                                font: font,
                                textBaseline: "top",
                                offsetY: 10,
                                fill: new Fill({ color: color }),
                                stroke: new Stroke({
                                    color: "black",
                                    width: 1,
                                }),
                            })
                        })
                    ]
                );
            };
        case 'Polygon':
        case 'LineString':
            return (
                [
                    new Style({
                        stroke: new Stroke({
                            color: outColor,
                            width: width + 2,
                            lineDash: [4, 8]
                        })
                    }),
                    new Style({
                        stroke: new Stroke({
                            color: color,
                            width: width,
                            lineDash: [4, 8]
                        }),
                        text: new Text({
                            text: label,
                            font: font,
                            textBaseline: "bottom",
                            placement: "line",
                            fill: new Fill({ color: color }),
                            stroke: new Stroke({
                                color: "black",
                                width: 1,
                            }),
                        })
                    }),
                    ...(measures ? getSegmentsStyles(feature, color, outColor) : [] )
                ]
            );
        default:
            return [];
    };
};