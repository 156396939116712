// Inspired from https://tiagohorta1995.medium.com/dynamic-list-virtualization-using-react-window-ab6fbf10bfb2
import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce } from 'usehooks-ts';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import Badge, { BadgeProps } from '@mui/material/Badge';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { RootState } from '../../app/store';
import { ConversationType, MessageType } from '../../redux/chat/chatSlice';
import { sameDay, longDay, short_hour } from '../../utils/TimeUtils';
import StringUtils from '../../utils/StringUtils';
import { chatActions } from '../../redux/chat/chatSlice';
import { widgetActions } from '../../redux/widget/widgetSlice';
import { GROUND_PLID } from '../../config/const';
import { ALL_MESSAGES_CHANNEL_ID, MESSAGES_CHUNK_LENGTH } from '../../config/chat';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: 'red',
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

function DayDivider(props: any) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ flexGrow: 1, borderBottom: "1px solid rgba(128, 128, 128, .25)" }} />
            <Typography variant="body1" sx={{ padding: "0 20px 0 20px", color: "rgba(128, 128, 128, .7)" }}>
                {props.children}
            </Typography>
            <Box sx={{ flexGrow: 1, borderBottom: "1px solid rgba(128, 128, 128, .25)" }} />
        </Box>
    );
};

const Message = React.memo(function Message({ message, previous, isBroadcast, isAllMessages, widget }: { message: MessageType, previous: MessageType | null, isBroadcast: boolean, isAllMessages: boolean, widget: any }) {

    const dispatch = useDispatch();
    const same_day = previous && sameDay(message.created, previous.created);
    const isGround = message.creator_id === GROUND_PLID;
    const color = useSelector((state: RootState) => {
        if (isGround) return "green";
        else return state.nav.tracking[message.creator_id]?.color
    });
    const rotation = isGround ? "-90" : "90";
    const name = useSelector((state: RootState) => {
        if (isGround) return 'GROUND';
        if (!state.mission.active) return 'UNDEF';
        else return state.mission.allowed[state.mission.active].fleet.find((v: any) => v.id === message.creator_id)?.name || message.creator_id;
    });
    const channel = useSelector((state: RootState) => {
        if (!isAllMessages) return null;
        return state.chat.conversations.list.find((c: ConversationType) => c.id === message.conversation);
    });

    const onChannelClick = () => {
        if (widget) {
            dispatch(widgetActions.set_widget_params({ gridId: widget.gridId, id: widget.widgetId, params: { conversationId: channel?.id } }));
        } else {
            dispatch(chatActions.set_active_conversation({ id: channel?.id }));
        }
    };

    return (
        <Box>
            {(!same_day || !previous) && (
                <DayDivider>{longDay(message.created)}</DayDivider>
            )}
            <Stack direction="row" spacing={0.5}>
                <Stack direction="column" spacing={0} sx={{ minWidth: '80px', width: '80px', overflow: 'hidden' }}>
                    <PlayArrowIcon
                        fontSize="large"
                        sx={{
                            color,
                            transform: `rotate(${rotation}deg)`,
                            width: "100%"
                        }}
                    />
                    <Typography variant="caption" sx={{ fontWeight: 'bold', wordBreak: 'break-all' }} align="center">
                        {name}
                    </Typography>
                </Stack>
                <Stack direction="column" spacing={0}>
                    <Grid container spacing={0}>
                        <Grid item sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
                            <Typography
                                variant="h6"
                                sx={{
                                    color: StringUtils.stringToColor(message.author),
                                    fontStyle: 'italic',
                                    fontWeight: 'bold'
                                }}
                            >
                                {message.author}
                            </Typography>
                        </Grid>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                            <Stack direction="row" spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                {
                                    channel &&
                                    <>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                fontStyle: 'italic',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            on
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            onClick={onChannelClick}
                                            sx={{
                                                fontStyle: 'italic',
                                                fontWeight: 'bold',
                                                color: '#A9A9A9',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {channel.name}
                                        </Typography>
                                    </>
                                }
                                <Typography
                                    variant="body1"
                                    sx={{ color: "grey" }}
                                >
                                    {short_hour(message.created)}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Typography
                        variant="body1"
                        sx={[{
                            fontSize: '1.1rem',
                            overflowWrap: 'break-word',
                            wordWrap: 'break-word',
                            wordBreak: 'break-word',
                        }, isBroadcast && { color: 'red' }]}
                    >
                        {message.text}
                    </Typography>
                </Stack>
            </Stack>
        </Box>
    )
});

export default function Messages({ conversation, messages, widget }: { conversation: ConversationType, messages: MessageType[], widget?: any }) {
    const dispatch = useDispatch();
    const listRef = useRef<null | HTMLDivElement>(null);
    const bottomRef = useRef<null | HTMLDivElement>(null);
    const broadcastChannels = useSelector((state: RootState) => state.chat.conversations.broadcastChannels);
    const [follow, setFollow] = useState(true);
    const [maxMessages, setMaxMessages] = useState(MESSAGES_CHUNK_LENGTH);
    const [focus, setFocus] = useState(true);

    const scrollToBottom = useCallback(() => {
        bottomRef.current?.scrollIntoView({ behavior: "auto" });
    }, []);

    const handleScroll = useCallback((event: any) => { //https://github.com/bvaughn/react-window/issues/573
        const bottom = Math.abs(event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight) <= 1;
        const top = event.currentTarget.scrollTop === 0;

        setFollow(bottom);
        if (top && messages.length > maxMessages) {
            setMaxMessages(max => max + MESSAGES_CHUNK_LENGTH);
            listRef.current?.scrollBy(0, 1); // scroll down by 1 pixel to allow scroll up again
        }
    }, [messages, maxMessages, setMaxMessages, setFollow]);

    const onFocus = () => setFocus(true);
    const onBlur = () => setFocus(false);

    useEffect(() => {
        if (messages.length > 0 && follow) scrollToBottom();
    });

    useEffect(() => {
        if (focus && follow) dispatch(chatActions.reset_conversation_unread({ id: conversation.id }));
    }, [conversation.id, messages, focus, follow, dispatch]);

    useEffectOnce(() => {
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
        };
    });

    return (
        <Box
            ref={listRef}
            sx={{
                width: '100%',
                height: '100%',
                overflowY: "auto",
                overflowX: "hidden",
                userSelect: "text"
            }}
            onScroll={handleScroll}
        >
            {messages.length > maxMessages &&
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ flexGrow: 1 }} />
                    <ArrowUpwardIcon fontSize='small' sx={{ color: "rgba(128, 128, 128, .7)" }} />
                    <Typography variant="body1" sx={{ marginLeft: (theme) => theme.spacing(1), color: "rgba(128, 128, 128, .7)" }}>
                        Scroll up to display more messages
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                </Box>
            }
            <Stack direction="column" spacing={2}>
                {
                    messages.slice((-maxMessages)).map((m: any, index: number) => {
                        return (
                            <Message
                                key={m.id}
                                message={m}
                                previous={index > 0 ? messages.slice((-maxMessages))[index - 1] : null}
                                isBroadcast={broadcastChannels.map((b: ConversationType) => b.id).includes(m.conversation)}
                                isAllMessages={conversation.id === ALL_MESSAGES_CHANNEL_ID}
                                widget={widget}
                            />
                        )
                    })
                }
            </Stack>
            {!follow &&
                <Fab
                    sx={{
                        position: "absolute",
                        right: (theme) => theme.spacing(2),
                        bottom: (theme) => theme.spacing(14),
                    }}
                    size="large"
                    color="secondary"
                    onClick={scrollToBottom}
                >
                    <StyledBadge badgeContent={conversation.unread} anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}>
                        <KeyboardArrowDownIcon fontSize='large' />
                    </StyledBadge>
                </Fab>
            }
            <div ref={bottomRef} />
        </Box>
    );
};