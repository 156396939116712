import mqtt from "mqtt/dist/mqtt";

export const MQTTconfig = {
    URL: `${window.location.protocol.includes("https") ? "wss" : "ws"}://${window.location.hostname}:${window.location.port}/mqtt`
  };  

export default class clientMQTT {
  constructor(config) {
    this.config = config;
    this.socket = null;
    this.options = {
      keepalive: 60
    };
  };

  connect(messageCallback, errorCallback, reconnectedCallback) {
    if (this.socket) return(new Promise((resolve, reject) => {resolve({result: "already connected"})}));
    this.socket = mqtt.connect(this.config.URL, this.options);
    return new Promise((resolve, reject) => {
      this.socket.on("connect", () => {
        resolve({ result: "connected" });
      });
      if (messageCallback) {
        this.socket.on("message", messageCallback);
      }
      if (reconnectedCallback) {
        this.socket.on("connect", reconnectedCallback);
      }
      if (errorCallback) {
        this.socket.on("error", error => errorCallback(error));
        this.socket.on("close", () => errorCallback({ cause: "close" }));
        this.socket.on("disconnect", () => errorCallback({ cause: "disconnect" }));
        this.socket.on("offline", () => errorCallback({ cause: "offline" }));
        this.socket.on("end", () => errorCallback({ cause: "end" }));
      }
    });
  };

  disconnect() {
    return new Promise(resolve => {
      this.socket.end(false, null, () => {
        this.socket = null;
        resolve();
      });
    });
  };

  publish(topic, message, options) {
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject("No mqtt connection.");

      return this.socket.publish(topic, message, options, error => {
        if (error) {
          return reject(error);
        }

        return resolve("published");
      });
    });
  };

  subscribe(topic, options) {
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject("No mqtt connection.");

      return this.socket.subscribe(topic, options, error => {
        if (error) {
          return reject(error);
        }

        return resolve("subscribed");
      });
    });
  };

  unsubscribe(topic, options) {
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject("No mqtt connection.");

      return this.socket.unsubscribe(topic, options, error => {
        if (error) {
          return reject(error);
        }

        return resolve("unsubscribed");
      });
    });
  };

  clearSubscriptions(topics) {
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject("No mqtt connection.");
      if (topics.length > 0) {
        return this.socket.unsubscribe(topics, {}, error => {
          if (error) {
            return reject(error);
          }
          return resolve("subscriptions cleared");
        });
      } else {
        return resolve("subscriptions cleared");
      }
    });
  };

  on(event, fun) {
    // No promise is needed here, but let's be consistent.
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject("No mqtt connection.");

      this.socket.on(event, fun);
      resolve();
    });
  };
};