import Login from "../components/login/Login";
import Mission from "../components/loading/Mission";
import Check from "../components/loading/Check";
import Error from "../components/error/Error";
import Dashboard from "../components/dashboard/Dashboard";
import { NAVIGATION_SERVICES, ServiceType } from './nav';
import { AUTH_LEVELS, AuthLevelObject } from './auth';
import NavIcon from "../components/navigation/NavIcon";
import ChatIcon from "../components/chat/ChatIcon";
import TelemetryIcon from "../components/telemetry/TelemetryIcon";
import TransferIcon from "../components/transfer/TransferIcon";
import HistoryIcon from '@mui/icons-material/History';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

export interface AppRouteType {
  id: string;
  label: string;
  path: string;
  component: (props?: any) => JSX.Element | null;
  exact: boolean;
  accessRights: AuthLevelObject;
  default?: boolean;
  children?: AppRouteType[];
};

export const APP_ROUTES: AppRouteType[] = [
  {
    id: "login",
    label: "Login",
    path: "/login",
    component: Login,
    exact: true,
    accessRights: AUTH_LEVELS.not_logged_in
  },
  {
    id: "error",
    label: "Error",
    path: "/error",
    exact: true,
    component: Error,
    accessRights: AUTH_LEVELS.passive,
  },
  {
    id: "checkToken",
    label: "Check Token",
    path: "/checkToken",
    component: Check,
    default: true,
    exact: true,
    accessRights: AUTH_LEVELS.not_logged_in
  },
  {
    id: "getMission",
    label: "Get Missions",
    path: "/getMissions",
    component: Mission,
    exact: true,
    accessRights: AUTH_LEVELS.passive
  },
  {
    id: "dashboard",
    label: "Dashboard",
    exact: false,
    path: "/:missionId/dashboard",
    component: Dashboard,
    accessRights: AUTH_LEVELS.passive,
  },
  {
    id: "access-denied",
    label: "Access Denied",
    path: "/access-denied",
    exact: true,
    component: Error,
    accessRights: AUTH_LEVELS.not_logged_in,
  },
];

export interface DashboadRouteType {
  id: string;
  label: string;
  path: string;
  icon: any;
  componentPath?: string;
  exact: boolean;
  accessRights: AuthLevelObject;
  default?: boolean;
  config?: any;
  redirect?: string;
  target?: string;
  rel?: string;
  more?: boolean;
  groundOnly?: boolean;
  disabled?: boolean;
};

export const DASHBOARD_ROUTES: DashboadRouteType[] = [
  {
    id: "navigation",
    label: "Navigation",
    exact: true,
    default: true,
    path: "/nav/",
    icon: NavIcon,
    componentPath: "navigation/Navigation",
    config: NAVIGATION_SERVICES,
    accessRights: AUTH_LEVELS.passive,
  },
  {
    id: "chat",
    label: "Chat",
    exact: true,
    path: "/chat/",
    icon: ChatIcon,
    componentPath: "chat/Chat",
    accessRights: AUTH_LEVELS.passive,
  },
  {
    id: "telemetry",
    label: "Telemetry",
    exact: true,
    path: "/telemetry/",
    icon: TelemetryIcon,
    componentPath: "telemetry/Telemetry",
    accessRights: AUTH_LEVELS.passive,
  },
  {
    id: "file-transfer",
    label: "File Transfer",
    exact: true,
    path: "/filetransfer/",
    icon: TransferIcon,
    componentPath: "transfer/Transfer",
    accessRights: AUTH_LEVELS.passive,
  },
  {
    id: "history",
    label: 'History',
    exact: true,
    path: "/history/",
    icon: HistoryIcon,
    componentPath: "history/History",
    more: true,
    accessRights: AUTH_LEVELS.passive
  },
  {
    id: "admin",
    label: 'Admin',
    exact: true,
    path: "/sys-admin/",
    icon: SupervisorAccountIcon,
    componentPath: "admin/Admin",
    more: true,
    accessRights: AUTH_LEVELS.admin,
  },
  {
    id: "um",
    label: "User Manual",
    exact: true,
    path: '',
    redirect: "/docs/um/",
    target: "_blank",
    rel: "noopener noreferrer",
    icon: LibraryBooksIcon,
    more: true,
    accessRights: AUTH_LEVELS.not_logged_in,
  }
];

export const excludeRoutes = (excludedIds: string[], routes: (DashboadRouteType | ServiceType)[]) => {
  return routes.filter(route => !excludedIds.includes(route.id));
};