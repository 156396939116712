import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { missionActions } from '../mission/missionSlice';
import { replayActions } from '../replay/replaySlice';
import { COLOR_TRACKING_FUNCTIONS, COLOR_TRACKING_BY_ALT } from '../../config/tracking';
import StringUtils from '../../utils/StringUtils';

export interface TrackerType {
    status: string,
    name: string,
    kind: string,
    virtual: boolean,
    shown: boolean,
    hud: {
        shown: boolean,
        values: any,
    },
    eta: {
        drawing: boolean,
        targets: any[],
    },
    circles: boolean,
    follow: boolean,
    length: number,
    source: string | null,
    color: string;
    colorTrack: string;
}

export interface TrackingState {
    [key: string]: TrackerType
};

const initialState: TrackingState = {};

const getValuesFromLocalStorage = (plid: string, hud_values: any) => {
    const ls = localStorage.getItem("_tracking");
    const defaults = {
        shown: true,
        circles: true,
        follow: false,
        length: 1800,
        source: null,
	color: StringUtils.stringToColor(plid, true),
        colorTrack: Object.entries(COLOR_TRACKING_FUNCTIONS).find(([key, el]) => el.default===true)?.[0] || COLOR_TRACKING_BY_ALT,
        hud: {
            shown: true,
            values: hud_values
        }
    };

    if (!ls || !plid) return defaults;

    const json = JSON.parse(ls);

    if (!(plid in json)) return defaults;

    return {
        shown: typeof json[plid].shown !== 'undefined' ? json[plid].shown : defaults.shown,
        circles: typeof json[plid].circles !== 'undefined' ? json[plid].circles : defaults.circles,
        follow: typeof json[plid].follow !== 'undefined' ? json[plid].follow : defaults.follow,
        length: typeof json[plid].length !== 'undefined' ? json[plid].length : defaults.length,
        source: typeof json[plid].source !== 'undefined' ? json[plid].source : defaults.source,
	color: typeof json[plid].color !== 'undefined' ? json[plid].color : defaults.color,
        colorTrack: typeof json[plid].colorTrack !== 'undefined' ? json[plid].colorTrack : defaults.colorTrack,
        hud: {
            shown: typeof json[plid].hud !== 'undefined' && typeof json[plid].hud.shown !== 'undefined' ? json[plid].hud.shown : defaults.hud.shown,
            values: hud_values
        }
    };
};

const trackingSlice = createSlice({
    name: 'tracking',
    initialState,
    reducers: {
        add_tracker: ((state, action) => {
            state[action.payload.plid] = {
                status: 'unknown',
                name: action.payload.name,
                kind: action.payload.kind,
                virtual: action.payload.virtual,
                eta: {
                    drawing: false,
                    targets: []
                },
                ...getValuesFromLocalStorage(action.payload.plid, action.payload.config?.hud?.values)
            }
        }),
        show: ((state, action) => {
            state[action.payload.plid].shown = true;
        }),
        hide: ((state, action) => {
            state[action.payload.plid].shown = false;
        }),
        show_circles: ((state, action) => {
            state[action.payload.plid].circles = true;
        }),
        hide_cirlces: ((state, action) => {
            state[action.payload.plid].circles = false;
        }),
        set_follow: ((state, action) => {
            for (const key in state) {
                if (state.hasOwnProperty(key)) {
                    if (key === action.payload.plid) {
                        state[key].follow = action.payload.follow;
                    } else {
                        state[key].follow = false;
                    }
                }
            }
        }),
        clear_follow: ((state, action) => {
            for (const key in state) {
                if (state.hasOwnProperty(key)) {
                    state[key].follow = false;
                }
            }
        }),
        show_hud: ((state, action) => {
            state[action.payload.plid].hud.shown = true;
        }),
        hide_hud: ((state, action) => {
            state[action.payload.plid].hud.shown = false;
        }),
        set_tracker_length: ((state, action) => {
            state[action.payload.plid].length = action.payload.length;
        }),
        set_tracker_source: ((state, action) => {
            if (action.payload.plid in state) state[action.payload.plid].source = action.payload.source;
        }),
        set_status: ((state, action) => {
            if (action.payload.plid in state) state[action.payload.plid].status = action.payload.status;
        }),
        set_tracker_color_track: ((state, action) => {
            state[action.payload.plid].colorTrack = action.payload.colorTrack;
        }),
	    set_color: ((state, action) => {
            state[action.payload.plid].color = action.payload.color;
        }),
    },
    extraReducers: (builder) => {
        builder.addCase(missionActions.set_active_mission, state => {
            for (let key in state) delete state[key];
            Object.assign(state, initialState);
        });
        builder.addCase(replayActions.start, state => {
            for (let key in state) delete state[key];
            Object.assign(state, initialState);
        });
        builder.addCase(replayActions.stop, state => {
            for (let key in state) delete state[key];
            Object.assign(state, initialState);
        });
    }
});

export const selectTracking = (state: RootState) => state.nav.tracking;

export const trackingActions = trackingSlice.actions;

export default trackingSlice.reducer;
