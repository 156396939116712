import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TwitterPicker } from 'react-color';
import produce from "immer";
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Popover from '@mui/material/Popover';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import PaletteIcon from '@mui/icons-material/Palette';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { PlotType, SerieType } from './TimeSeriesTab';
import ParamSelectDialog from './ParamSelectDialog';
import { RootState } from '../../../app/store';

const DEFAULT_COLORS = ['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF'];

interface SideProps {
    serie: SerieType,
    index: number,
    setSerieParams: (s: SerieType) => void,
    deleteSerie: (id: string) => void,
    moveSerie: (id: string, newIndex: number) => void,
    handleClose: () => void,
    forceRemount: () => void,
};

const SideHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(3),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
}));

const CustomTableCell = styled(TableCell)(({ theme }) => ({
    padding: 0
}));

export function ColorPalette({ color, onChange, size = "medium", colors = DEFAULT_COLORS }: { color: string, onChange: (newColor: string) => void, size?: "small" | "medium" | "large" | undefined, colors?: any[] | undefined }) {

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleColorChange = (c: any) => {
        onChange(c.hex);
        handlePopoverClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'palette-popover' : undefined;

    return (
        <>
            <Tooltip title="Change color" placement="right">
                <IconButton sx={{ backgroundColor: color }} onClick={handleClick} size={size}>
                    <PaletteIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <TwitterPicker
                    color={color}
                    onChangeComplete={handleColorChange}
                    colors={colors}
                />
            </Popover>
        </>
    );
};

function ColorSettings({ color, onChange }: { color: string, onChange: (color: string) => void }) {
    return (
        <Stack spacing={2} direction="row" alignItems="center">
            <Typography variant="subtitle1" sx={{ fontSize: '1.2rem' }}>
                Color:
            </Typography>
            <ColorPalette color={color} onChange={onChange} />
        </Stack>
    );
};

function CorrelationActions({ param, removeCorrelation, changeCorrelationColor, toggleCorrelation }: { param: PlotType, removeCorrelation: (param: PlotType) => void, changeCorrelationColor: (param: PlotType, newColor: string) => void, toggleCorrelation: (param: PlotType) => void }) {

    const handleColorChange = (color: string) => {
        changeCorrelationColor(param, color);
    };

    return (
        <Stack spacing={2} direction="row" justifyContent="flex-end">
            <ColorPalette color={param.color} onChange={handleColorChange} size="small" />
            <IconButton
                size="small"
                onClick={() => toggleCorrelation(param)}
            >
                {param.shown ? <VisibilityIcon fontSize="inherit" /> : <VisibilityOffIcon fontSize="inherit" />}
            </IconButton>
            <IconButton size="small" onClick={() => removeCorrelation(param)}>
                <DeleteIcon fontSize="inherit" />
            </IconButton>
        </Stack>
    );
};

const CorrelationName = React.memo(function CorrelationName({ source, label, param }: { source: string, label: string, param: string }) {
    const display_name = useSelector((state: RootState) => {
        if (!state.mission.active) return 'UNDEF';
        else return state.mission.allowed[state.mission.active].fleet.find((v: any) => v.id === source)?.name;
    });
    return (<>{`${display_name}-${label}-${param}`}</>);
});

function Correlate({ correlations, addCorrelation, removeCorrelation, changeCorrelationColor, toggleCorrelation, exclude = [] }: { correlations: PlotType[], addCorrelation: (param: PlotType) => void, removeCorrelation: (param: PlotType) => void, changeCorrelationColor: (param: PlotType, newColor: string) => void, toggleCorrelation: (param: PlotType) => void, exclude?: string[] }) {
    const [paramsDialogOpen, setParamsDialogOpen] = useState(false);

    const handleAddClick = () => {
        setParamsDialogOpen(true);
    };

    const handleClose = () => {
        setParamsDialogOpen(false);
    };

    const confirm = (param: PlotType) => {
        addCorrelation({ ...param, shown: true });
        setParamsDialogOpen(false);
    };

    return (
        <>
            <Stack spacing={2} padding={1}>
                <Stack spacing={2} direction="row" alignItems="center">
                    <Typography variant="subtitle1" sx={{ fontSize: '1.2rem' }}>
                        Correlations:
                    </Typography>
                    <IconButton onClick={handleAddClick}>
                        <AddCircleIcon />
                    </IconButton>
                </Stack>
                <TableContainer style={{ marginTop: 0 }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell>Param</CustomTableCell>
                                <CustomTableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {correlations.map((p: PlotType) => (
                                <TableRow
                                    key={`${p.source}-${p.label}-${p.param}`}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <CustomTableCell component="th" scope="row">
                                        <CorrelationName source={p.source} label={p.label} param={p.param} />
                                    </CustomTableCell>
                                    <CustomTableCell component="th" scope="row" align='right'>
                                        <CorrelationActions param={p} removeCorrelation={removeCorrelation} changeCorrelationColor={changeCorrelationColor} toggleCorrelation={toggleCorrelation} />
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <ParamSelectDialog open={paramsDialogOpen} confirm={confirm} close={handleClose} exclude={exclude} />
            </Stack>
        </>
    );
};

function Reorder({ moveUp, moveDown }: { moveUp: () => void, moveDown: () => void }) {
    return (
        <Stack spacing={2} direction="row" alignItems="center">
            <Typography variant="subtitle1" sx={{ fontSize: '1.2rem' }}>
                Position:
            </Typography>
            <Stack spacing={1} direction="row">
                <IconButton onClick={moveUp}>
                    <ArrowUpwardIcon />
                </IconButton>
                <IconButton onClick={moveDown}>
                    <ArrowDownwardIcon />
                </IconButton>
            </Stack>
        </Stack>
    );
};

function Scaling({ main, setScale, setScaleType, forceRemount }: { main: PlotType, setScale: (autoscale: boolean, min: number, max: number) => void, setScaleType: (type: string) => void, forceRemount: () => void }) {

    const [min, setMin] = useState(main.min);
    const [max, setMax] = useState(main.max);

    const handleScaleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setScaleType(event.target.value);
        forceRemount(); // modifying options with new scales does not trigger a re-rendering of the plot so we force remount so that the scales modif is applied
    };

    const handleAutoscaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setScale(event.target.checked, -Infinity, Infinity);
            forceRemount(); // modifying options with new scales does not trigger a re-rendering of the plot so we force remount so that the scales modif is applied
        } else setScale(event.target.checked, min, max);
    };

    const handleSetAutoScaleValues = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setScale(false, min, max);
        forceRemount(); // modifying options with new scales does not trigger a re-rendering of the plot so we force remount so that the scales modif is applied
    };

    const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMin(Number(event.target.value));
    };

    const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMax(Number(event.target.value));
    };

    useEffect(() => {
        setMin(main.min);
        setMax(main.max);
    }, [main]);

    return (
        <>
            <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="subtitle1" sx={{ fontSize: '1.2rem' }}>
                    Y Scale:
                </Typography>
                <TextField
                    select
                    value={main.scale}
                    onChange={handleScaleTypeChange}
                    variant="outlined"
                    size="small"
                    SelectProps={{
                        native: true,
                    }}
                >
                    <option value={'linear'}>Linear</option>
                    <option value={'log'}>Logarithmic</option>
                </TextField>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="subtitle1" sx={{ fontSize: '1.2rem' }}>
                    Autoscale:
                </Typography>
                <Switch
                    color="secondary"
                    checked={main.autoscale}
                    onChange={handleAutoscaleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </Stack>
            {
                !main.autoscale &&
                <>
                    <Stack spacing={1} direction="row" alignItems="center">
                        <Typography variant="subtitle1" sx={{ fontSize: '1rem' }}>
                            Min:
                        </Typography>
                        <TextField
                            id="min"
                            type="number"
                            size="small"
                            disabled={main.autoscale}
                            value={min}
                            onChange={handleMinChange}
                        />
                    </Stack>
                    <Stack spacing={1} direction="row" alignItems="center">
                        <Typography variant="subtitle1" sx={{ fontSize: '1rem' }}>
                            Max:
                        </Typography>
                        <TextField
                            id="max"
                            type="number"
                            size="small"
                            disabled={main.autoscale}
                            value={max}
                            onChange={handleMaxChange}
                        />
                    </Stack>
                    <Button variant='contained' onClick={handleSetAutoScaleValues} disabled={min === main.min && max === main.max}>APPLY</Button>
                </>
            }
        </>
    );
};

export default function Side({ serie, index, setSerieParams, deleteSerie, moveSerie, handleClose, forceRemount }: SideProps) {

    const handleColorChange = (color: string) => {
        const newSeries = produce(serie, (draft: SerieType) => {
            draft.main.color = color;
        });
        setSerieParams(newSeries);
    };

    const handleAddCorrelation = (param: PlotType) => {
        const newSeries = produce(serie, (draft: SerieType) => {
            draft.others.push(param);
        });
        setSerieParams(newSeries);
    };

    const handleRemoveCorrelation = (param: PlotType) => {
        const newSeries = produce(serie, (draft: SerieType) => {
            const index = serie.others.findIndex((p: PlotType) => (p.source === param.source && p.label === param.label && p.param === param.param));
            if (index !== -1) {
                return {
                    ...serie,
                    others: [...serie.others.slice(0, index), ...serie.others.slice(index + 1)]
                }
            };
        });
        setSerieParams(newSeries);
    };

    const handleCorrelationColorChange = (param: PlotType, newColor: string) => {
        const newSeries = produce(serie, (draft: SerieType) => {
            const index = serie.others.findIndex((p: PlotType) => (p.source === param.source && p.label === param.label && p.param === param.param));
            if (index !== -1) draft.others[index].color = newColor;
        });
        setSerieParams(newSeries);
    };

    const handleToggleCorrelation = (param: PlotType) => {
        const newSeries = produce(serie, (draft: SerieType) => {
            const index = serie.others.findIndex((p: PlotType) => (p.source === param.source && p.label === param.label && p.param === param.param));
            if (index !== -1) draft.others[index].shown = !serie.others[index].shown;
        });
        setSerieParams(newSeries);
    };

    const moveUp = () => {
        moveSerie(serie.id, index - 1);
    };

    const moveDown = () => {
        moveSerie(serie.id, index + 1);
    };

    const handleSetScale = (autoscale: boolean, min: number, max: number) => {
        const newSeries = produce(serie, (draft: SerieType) => {
            draft.main.autoscale = autoscale;
            draft.main.min = min;
            draft.main.max = max;
        });
        setSerieParams(newSeries);
    };

    const handleSetScaleType = (type: string) => {
        const newSeries = produce(serie, (draft: SerieType) => {
            draft.main.scale = type;
        });
        setSerieParams(newSeries);
    };

    if (!serie) return null;
    return (
        <>
            <SideHeader>
                <IconButton onClick={handleClose} size="large">
                    <KeyboardDoubleArrowLeftIcon fontSize="inherit" />
                </IconButton>
                <Stack direction="row" spacing={1}>
                    <div>{serie.main.source}</div>
                    <div>{serie.main.label}</div>
                    <div>{serie.main.param}</div>
                </Stack>
            </SideHeader>
            <Divider />
            <Box sx={{ maxHeight: 'calc(100% - 74px)', overflow: 'auto' }}>
                <Paper sx={{ margin: 1, padding: 0.5 }}>
                    <Stack spacing={2} padding={1}>
                        <ColorSettings color={serie.main.color} onChange={handleColorChange} />
                        <Scaling main={serie.main} setScale={handleSetScale} setScaleType={handleSetScaleType} forceRemount={forceRemount} />
                        <Reorder moveUp={moveUp} moveDown={moveDown} />
                    </Stack>
                </Paper>
                <Paper sx={{ margin: 1, padding: 0.5 }}>
                    <Correlate
                        correlations={serie.others}
                        addCorrelation={handleAddCorrelation}
                        removeCorrelation={handleRemoveCorrelation}
                        changeCorrelationColor={handleCorrelationColorChange}
                        toggleCorrelation={handleToggleCorrelation}
                        exclude={[...serie.others.map((p: PlotType) => `${p.source}-${p.label}-${p.param}`), `${serie.main.source}-${serie.main.label}-${serie.main.param}`]}
                    />
                </Paper>
                <Paper sx={{ margin: 1 }}>
                    <Button variant='contained' onClick={() => deleteSerie(serie.id)} fullWidth>DELETE</Button>
                </Paper>
            </Box>
        </>
    );
};