import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import EditIcon from "@mui/icons-material/Edit";
import { widgetActions } from "../../redux/widget/widgetSlice";
import WidgetTemplate from "../widgets/WidgetTemplate";
import { COLUMNS, MOBILE_COLUMNS, paramsSelector } from "./TelemetryListTab";
import TelemetryWidgetDialog from "./TelemetryWidgetDialog";

type SelectorParamType = {
    source: string,
    label: string,
    issued: string,
    params: string[][]
};

export type WidgetParamsType = {
    [source: string]: {
        [label: string]: string[]
    }
};

type DisplayParamsType = {
    [source: string]: {
        [label: string]: {
            issued: string,
            source: string,
            label: string,
            params: string[][]
        }
    }
};

function filterParams(selectorParams: SelectorParamType[], widgetParams: WidgetParamsType) {
    let result: DisplayParamsType = {};
    const filteredFrames = selectorParams.filter(param =>
        Object.keys(widgetParams).includes(param.source)
        && Object.keys(widgetParams[param.source]).includes(param.label)
    );
    filteredFrames.forEach(frame => {
        if (!result[frame.source]) result[frame.source] = {};
        if (!result[frame.source][frame.label]) result[frame.source][frame.label] = {
            issued: frame.issued,
            source: frame.source,
            label: frame.label,
            params: []
        };

        frame.params.filter(param =>
            widgetParams[frame.source][frame.label].includes(param[0])
        ).forEach(param =>
            result[frame.source][frame.label].params.push(param)
        );
    });
    return result;
};

const TelemetryWidget = React.memo((props: any) => {
    const dispatch = useDispatch();
    const availableParams: any[] = useSelector(paramsSelector);
    const { id, gridId, reduced, params, width, height } = props;
    const { widgetParams, isMobileDisplay } = params;

    const displayedParams = filterParams(availableParams, widgetParams);

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const openDialog = () => {
        setDialogOpen(true);
    };

    const alternateDisplay = () => {
        dispatch(widgetActions.set_widget_params({ gridId, id, params: { widgetParams, isMobileDisplay: !isMobileDisplay } }));
    };

    return (
        <WidgetTemplate
            id={id}
            gridId={gridId}
            header={"Telemetry"}
            actions={[
                <Stack direction="row" spacing={2}>
                    <Tooltip title="Alternate display" placement="top">
                        <IconButton onClick={alternateDisplay}>
                            <AutorenewIcon fontSize="inherit"/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Change parameters" placement="top">
                        <IconButton onClick={openDialog}>
                            <EditIcon fontSize="inherit"/>
                        </IconButton>
                    </Tooltip>
                </Stack>
            ]}
            width={width}
            height={height}
            reduced={reduced}
        >
            <Box sx={{ width: '100%', height: '100%', overflow: 'scroll' }}>
                <TableContainer sx={{ userSelect: "text" }}>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                {(isMobileDisplay ? MOBILE_COLUMNS : COLUMNS).map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        sx={{ minWidth: column.minWidth, maxWidth: column.maxWidth, height: '50px', backgroundColor: (theme) => theme.palette.primary.main }}
                                    >
                                        <Typography variant="body1" sx={{ fontSize: '1rem', whiteSpace: "nowrap" }}>{column.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(displayedParams).map(([source, labels]) => {
                                return Object.entries(labels).map(([label, frame], index) => {
                                    return (
                                        <TableRow role="checkbox" tabIndex={-1} key={`${source}-${label}`} sx={{ backgroundColor: (theme) => theme.palette.primary.dark }}>
                                            {(isMobileDisplay ? MOBILE_COLUMNS : COLUMNS).map((column) => {
                                                const value = column.id !== "info" ? frame[column.id] : null;
                                                if (index !== 0 && column.id === "source") return;
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        sx={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                                                        align={column.align}
                                                        rowSpan={index === 0 && column.id === "source" ? Object.entries(labels).length : 1}
                                                    >
                                                        {column.id === "source" && column.format ?
                                                            <>
                                                                {index === 0 && column.format(value, frame.source, frame.label, frame.issued)}
                                                            </>
                                                        : column.format ?
                                                            column.format(value, frame.source, frame.label, frame.issued)
                                                        :
                                                            <Typography variant="body1" sx={{ fontSize: '1rem', whiteSpace: "nowrap", overflow: 'auto' }}>{value as string}</Typography>
                                                        }
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })
                            }).flat()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <TelemetryWidgetDialog open={dialogOpen} setOpen={setDialogOpen} gridId={gridId} widgetId={id} currentParams={params} isEdit={true}/>
        </WidgetTemplate>
    );
});

export default TelemetryWidget;