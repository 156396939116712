import { createSlice, current } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../../app/store';
import { missionActions } from '../mission/missionSlice';
import { replayActions } from '../replay/replaySlice';
import { DEFAULT_TELEMETRY_TAB_ID } from '../../config/telemetry';

type TelemetryConfigType = {
    [key: string]: {
        streams: any;
        controls: any;
    };
};

type MapMarkerType = {
    id: string;
    position: number[];
    color: string;
};

export type ComputeModuleType = {
    [name: string]: { label: string, source: string, params: { [internalName: string]: string } }
};

export type TabType = {
    id: string;
    label: string;
    closable?: boolean;
    widgetable?: boolean;
    params?: any[];
    filters?: any;
    sideOpen?: boolean | undefined;
};

export interface LabelType {
    label: string;
    is_source: boolean;
};

export interface TelemetryState {
    labels: {
        [key: string]: LabelType[];
    };
    streams: {
        [key: string]: {
            [key: string]: [number[], any[]] // [[timestamps], [values]]
        };
    };
    config: TelemetryConfigType;
    tabs: TabType[];
    activeTab: string;
    fetchError: string | null;
    mapMarkers: MapMarkerType[];
    computed: { [plid: string]: ComputeModuleType };
    stopped: { [label: string]: boolean };
};

const defaultTab = {
    id: DEFAULT_TELEMETRY_TAB_ID,
    label: 'Telemetry Streams',
    closable: false,
    widgetable: false,
    filters: {
        sources: [],
        labels: []
    }
};

const initialState: TelemetryState = {
    labels: {},
    streams: {},
    config: {},
    tabs: [defaultTab],
    activeTab: DEFAULT_TELEMETRY_TAB_ID,
    fetchError: null,
    mapMarkers: [],
    computed: {},
    stopped: {}
};

const telemetrySlice = createSlice({
    name: 'telemetry',
    initialState,
    reducers: {
        get_labels: ((state, action) => {
            state.labels[action.payload.plid] = [];
            state.fetchError = null;
        }),
        add_label: ((state, action) => {
            state.labels[action.payload.plid].push(action.payload.label);
            state.streams[action.payload.plid] = {
                ...state.streams[action.payload.plid],
                [action.payload.label.label]: [[], []]
            };
        }),
        get_labels_success: ((state, action) => { }),
        get_labels_fail: ((state, action) => {
            state.fetchError = 'Error while fechting telemetry labels';
        }),
        get_history_success: ((state, action) => {
            const { plid, label, data, is_source } = action.payload;
            if (!(plid in state.labels)) state.labels[plid] = [{ label, is_source }];
            state.streams[plid] = {
                ...state.streams[plid],
                [label]: data
            };
            state.fetchError = null;
        }),
        get_history_fail: ((state, action) => {
            state.fetchError = 'Error while fechting telemetry history';
        }),
        add_value: ((state, action) => {
            if (state.streams[action.payload.plid] && action.payload.label in state.streams[action.payload.plid]) {
                const index = state.streams[action.payload.plid][action.payload.label][0].findIndex((t) => t > action.payload.timestamp);
                if (index > 1) {
                    state.streams[action.payload.plid][action.payload.label][0].splice(index - 1, 0, action.payload.timestamp);
                    state.streams[action.payload.plid][action.payload.label][1].splice(index - 1, 0, action.payload.value);
                } else {
                    state.streams[action.payload.plid][action.payload.label][0].push(action.payload.timestamp);
                    state.streams[action.payload.plid][action.payload.label][1].push(action.payload.value);
                };
            } else {
                state.labels[action.payload.plid].push(action.payload.label);
                state.streams[action.payload.plid] = {
                    ...state.streams[action.payload.plid],
                    [action.payload.label]: [[action.payload.timestamp], [action.payload.value]]
                };
            };
        }),
        add_tab: ((state, action) => {
            let id = uuidv4();
            state.tabs.push({
                id,
                label: action.payload.label,
                params: action.payload.params,
                widgetable: action.payload.widgetable,
                closable: action.payload.closable,
                sideOpen: undefined
            });
            if (action.payload.active === true) state.activeTab = id;
        }),
        remove_tab: ((state, action) => {
            const index = state.tabs.findIndex((t: TabType) => t.id === action.payload.id);
            if (index !== -1) {
                if (state.tabs[index].id === state.activeTab) state.activeTab = DEFAULT_TELEMETRY_TAB_ID;
                state.tabs = [...state.tabs.slice(0, index), ...state.tabs.slice(index + 1)];
            }
        }),
        set_tabs: ((state, action) => {
            state.tabs = [
                ...(action.payload.noDefault ? [] : [defaultTab]),
                ...action.payload.tabs
            ];
        }),
        set_tab_params: ((state, action) => {
            const index = state.tabs.findIndex((t: TabType) => t.id === action.payload.id);
            if (index !== -1) {
                if (action.payload.params.length > 0) {
                    state.tabs[index].params = action.payload.params;
                    state.tabs[index].sideOpen = action.payload.sideOpen;
                } else {
                    // If params (series) list is empty, we remove tab
                    if (state.tabs[index].id === state.activeTab) state.activeTab = DEFAULT_TELEMETRY_TAB_ID;
                    state.tabs = [...state.tabs.slice(0, index), ...state.tabs.slice(index + 1)];
                };
            };
        }),
        set_active_tab: ((state, action) => {
            state.activeTab = action.payload.id;
        }),
        set_default_tab_filters: ((state, action) => {
            const index = state.tabs.findIndex((t: TabType) => t.id === DEFAULT_TELEMETRY_TAB_ID);
            if (index !== -1) {
                state.tabs[index].filters = action.payload.filters;
            }
        }),
        add_map_marker: ((state, action) => {
            const index = state.mapMarkers.findIndex((m: MapMarkerType) => m.id === action.payload.id);
            if (index !== -1) {
                state.mapMarkers[index] = {
                    ...state.mapMarkers[index],
                    position: action.payload.position,
                    color: action.payload.color
                };
            } else {
                state.mapMarkers.push(action.payload);
            };
        }),
        clear_map_markers: ((state, action) => {
            state.mapMarkers = [];
        }),
        update_stop: ((state, action) => {
        }),
        new_stop: ((state, action) => {
            const label = action.payload.label
            const stop = action.payload.stop;
            state.stopped[label] = stop;
        }),
        init_computed: ((state, action) => {
            const plid: string = action.payload.plid;
            const module: string = action.payload.module;
            const label: string = action.payload.label;
            const source: string = action.payload.source;
            const params: { [internalName: string]: string } = action.payload.params;
            state.computed[plid] = { ...state.computed[plid], [module]: { label, source, params } };
            state.streams[plid] = {
                ...state.streams[plid],
                [label]: action.payload.history
            }
        }),
        add_computed: ((state, action) => {
            const plid: string = action.payload.plid;
            const label: string = action.payload.label;
            const timestamp: number = action.payload.timestamp;
            const value: any = action.payload.value;

            if (label in state.streams[plid]) {
                const index = state.streams[plid][label][0].findIndex((t) => t > timestamp);
                if (index > 1) {
                    state.streams[plid][label][0].splice(index - 1, 0, timestamp);
                    state.streams[plid][label][1].splice(index - 1, 0, value);
                } else {
                    state.streams[plid][label][0].push(timestamp);
                    state.streams[plid][label][1].push(value);
                };
            };
        })
    },
    extraReducers: (builder) => {
        const resetStateExceptConfig = (state: TelemetryState) => {
            const { config } = state; // Preserve the current config
            Object.assign(state, { ...initialState, config }); // Reset state, keeping the config
        };

        builder.addCase(missionActions.set_active_mission, (state) => {
            Object.assign(state, initialState);
        });
        builder.addCase(replayActions.start, (state) => {
            resetStateExceptConfig(state);
        });
        builder.addCase(replayActions.stop, (state) => {
            resetStateExceptConfig(state);
        });
        builder.addCase(missionActions.set_active_config, (state, action) => {
            state.config = action.payload.config?.telemetry || null;
        });
    }
});

export const selectTelemetry = (state: RootState) => state.telemetry;

export const telemetryActions = telemetrySlice.actions;

export default telemetrySlice.reducer;