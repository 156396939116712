import { call, put, all, select, takeLatest, takeEvery } from "redux-saga/effects";
import { v4 as uuidv4 } from 'uuid';
import { missionActions } from './missionSlice';
import { authActions, AuthState } from "../auth/authSlice";
import { MISSION_API } from './missionApi';
import { RootState } from '../../app/store';
import { toastActions } from "../toast/toastSlice";
import { DASHBOARD_TOAST_CONTAINER_ID, DASHBOARD_TOAST_MISSION_CHANGED_ID } from '../../config/toast';

type WhatYouYield = any;
type WhatYouReturn = any;
type WhatYouAccept = any;

function* getMissions(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        const auth: AuthState = yield select((state: RootState) => state.auth);
        if (auth.token && auth.customerId && auth.user_pk) {
            const res: any = yield call(MISSION_API.getMissions, auth.token, auth.customerId, auth.user_pk);
            yield put(missionActions.get_missions_success(res?.data));
        };
    } catch (error: any) {
        yield put(missionActions.get_missions_failure({ status: error.response?.status, text: error.response?.statusText }));
    }
};

function* watchGetUserInfo() {
    yield takeLatest(authActions.get_user_info_success, getMissions);
};

function* setActiveMission(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const missionId = yield select((state: RootState) => state.mission.active);

    const activeMissionFromLS = localStorage.getItem('mission');

    if (missionId !== activeMissionFromLS) {
        localStorage.removeItem('activeConversationId');
        localStorage.removeItem('_telemetry_tabs');
        localStorage.removeItem('_widgets');
        localStorage.removeItem('_tracking');
        localStorage.removeItem('_customs');
        localStorage.removeItem('_weather');
        localStorage.removeItem('_aero');
        localStorage.removeItem('_chat');
        if (!action.payload?.ignoreLS) localStorage.setItem('mission', missionId);
    };

    const config = yield select((state: RootState) => state.mission.allowed[missionId].config);
    yield put(missionActions.set_active_config({ config }));
};

function* watchSetActiveMission() {
    yield takeEvery(missionActions.set_active_mission, setActiveMission);
};

function* addMissionToast(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const missionId = yield select((state: RootState) => state.mission.active);

    if (action.payload.id === missionId) {
        yield put(toastActions.add_toast({
            containerId: DASHBOARD_TOAST_CONTAINER_ID,
            toast: {
                id: `mission-${uuidv4()}`,
                variant: DASHBOARD_TOAST_MISSION_CHANGED_ID,
                severity: "success",
                autoHideDuration: null,
                message: `Current Mission ${action.payload.name} has changed. Refresh is required`,
                vertical: 'bottom',
                horizontal: 'left'
            }
        }));
    };

    yield call(getMissions, action);
};

function* watchNewMissionMessage() {
    yield takeEvery(missionActions.new_mission_message, addMissionToast);
};

export default function* watchMission() {
    yield all([
        watchGetUserInfo(),
        watchSetActiveMission(),
        watchNewMissionMessage(),
    ]);
};