import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { widgetActions } from "../../redux/widget/widgetSlice";
import { NAVIGATION_GRID_ID, TELEMETRY_WIDGET_ID } from "../../config/widget";
import { paramsSelector } from "./TelemetryListTab";
import { WidgetParamsType } from "./Widget";

type SelectParamType = {
    source: string,
    label: string,
    name: string
};

function formatParams(params: SelectParamType[]) {
    let result: {[key: string]: any} = {};
    params.forEach((param) => {
        if (!result[param.source]) result[param.source] = {};
        if (!result[param.source][param.label]) result[param.source][param.label] = [];
        result[param.source][param.label].push(param.name);
    });
    return result;
};

function unformatParams(sources: WidgetParamsType) {
    let result: {source: string, label: string, name: string}[] = [];
    Object.entries(sources).forEach(([source, labels]) => {
        Object.entries(labels).forEach(([label, params]) => {
            params.forEach(param => {
                result.push({source, label, name: param});
            });
        });
    });
    return result;
};

export default function TelemetryWidgetDialog({ open, setOpen, isEdit, gridId, widgetId, currentParams }: { open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>, isEdit?: boolean, gridId?: string, widgetId?: string, currentParams?: {widgetParams: WidgetParamsType, isMobileDisplay: boolean}}) {
    const medium = useMediaQuery((theme: any) => theme.breakpoints.down('xl'));
    const dispatch = useDispatch();

    const availableParams: any[] = useSelector(paramsSelector);
    const [params, setParams] = useState<any[]>(currentParams?.widgetParams ? unformatParams(currentParams.widgetParams) : []);

    const onDialogCancel = () => {
        setOpen(false);
        setParams(currentParams?.widgetParams ? unformatParams(currentParams.widgetParams) : []);
    };

    const onDialogChange = (event: React.SyntheticEvent, value: any, reason: string) => {
        setParams(value);
    };

    const addWidget = () => {
        setOpen(false);
        const widgetParams = formatParams(params);
        if (isEdit) {
            dispatch(widgetActions.set_widget_params({ gridId, id: widgetId, params: { widgetParams, isMobileDisplay: currentParams?.isMobileDisplay || false } }));
        } else {
            dispatch(widgetActions.add_widget({ gridId: NAVIGATION_GRID_ID, id: uuidv4(), kind: TELEMETRY_WIDGET_ID, params: { widgetParams, isMobileDisplay: false }, medium }));
        }
    };

    const isEqual = (option: any, selected: any) => {
        return option.source === selected.source && option.label === selected.label && option.name === selected.name;
    };

    const options = availableParams.map((frame: any) =>
        frame.params.map((param: any) => ({source: frame.source, label: frame.label, name: param[0]}))
    ).flat().filter((param: any) => param.name !== "position");

    return (
        <Dialog fullWidth open={open} onClose={onDialogCancel}>
            <DialogTitle>{isEdit ? "Edit" : "Add"} telemetry widget</DialogTitle>
            <DialogContent>
                <Autocomplete
                    fullWidth
                    size="small"
                    multiple
                    options={options}
                    filterSelectedOptions
                    isOptionEqualToValue={isEqual}
                    value={params}
                    onChange={onDialogChange}
                    getOptionLabel={(option: any) => `${option.source} - ${option.label} - ${option.name}`}
                    renderTags={(value: any[], getTagProps) =>
                        value.map((option: any, index: number) => (
                            <Chip variant="outlined" size="small" label={`${option.source} - ${option.label} - ${option.name}`} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select parameters"
                            placeholder="Parameters"
                        />
                    )}
                    sx={{paddingTop: 1}} // to prevent the TextField's label from being cut by the DialogTitle
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={onDialogCancel} >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={false}
                    onClick={addWidget}
                >
                    {isEdit ? "Edit" : "Create"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};