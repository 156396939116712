import React, { useEffect, useCallback, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from "@mui/material/Typography";
import TimerIcon from "@mui/icons-material/Timer";
import { mqttActions } from "../../../redux/mqtt/mqttSlice";
import { hourOrNbDays } from "../../../utils/TimeUtils";
import MQTTClient, { MQTTconfig } from "../../../utils/MQTTClient";


export default function DassaultLwcTop({ title, topicIn, topicOut, timeout }: { title: string, topicIn: string, topicOut: string, timeout: number }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [values, setValues] = useState(null as null | { issued: number })
    const [error, setError] = useState(false);
    const mqttClientRef = useRef(null as null | MQTTClient);
    const timer = useRef(0);

    const onMessage = useCallback((topic: string, message: string) => {
        if (topic === topicIn) {
            clearTimeout(timer.current);
            setError(false)
            setLoading(false);
            try {
                const decoded = JSON.parse(message.toString());
                setSuccess(true);
                setValues(decoded);
            } catch (e) {
                setSuccess(false);
                setValues(null);
            }
        }
    }, [topicIn]);

    useEffect(() => {
        if (mqttClientRef.current === null) {
            const mqttClient = new MQTTClient(MQTTconfig);
            mqttClientRef.current = mqttClient;
            mqttClient.connect(onMessage).then(() => {
                if (topicIn !== undefined) mqttClient.subscribe(topicIn)
            });
        }
        /*return () => { // TODO : seems to trigger too early...
            if (mqttClientRef.current?.socket) {
                mqttClientRef.current.unsubscribe(topicIn).then(() => {
                    mqttClientRef.current?.disconnect();
                    mqttClientRef.current = null;
                });
            }
        }*/
    }, [topicIn, onMessage]);

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const handleClick = useCallback(() => {
        if (!loading) {
            setSuccess(false);
            setError(false)
            setLoading(true);
            setValues(null);
            dispatch(mqttActions.publish({ topic: topicOut, payload: JSON.stringify({}) }));
            timer.current = window.setTimeout(() => {
                setSuccess(false);
                setError(true)
                setLoading(false);
                setValues(null);
            }, timeout * 1000);

        }
    }, [dispatch, topicOut, loading, timeout]);

    return (
        <LoadingButton
            variant="contained"
            size="large"
            color={success ? "success" : "error"}
            loading={loading}
            disabled={loading}
            startIcon={<TimerIcon />}
            onClick={handleClick}
            sx={{
                color: 'white'
            }}
        >
            {title}
            {values?.issued && <Typography variant="caption">{" : " + hourOrNbDays(values?.issued)}</Typography>}
            {error && <Typography variant="caption">: Timeout</Typography>}
        </LoadingButton>
    );
};