import React, { Suspense, lazy, useMemo } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Location } from 'history';
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { DASHBOARD_ROUTES, DashboadRouteType, excludeRoutes } from '../../config/routes';
import { AuthLevelObject } from "../../config/auth";
import { rightsCheckSelector } from './AppRoutes';
import Loading from '../loading/Loading';

function reduceRoutes(routes: DashboadRouteType[], prefix: string) {

    let routesList: any[] = [];

    routes.forEach(r => {
        const { id, exact, path, componentPath, default: def, config, ...rest } = r;
        const Component: React.LazyExoticComponent<React.ComponentType<any>> = lazy(() => import(`../${componentPath}`));
        routesList.push(
            <CheckedRoute
                key={id}
                exact={exact}
                path={`${prefix}${path}`}
                {...rest}
                component={Component}
                componentConfig={config}
            />
        )
        if (def) {
            routesList.push(<Route exact key={id + "default-route"} path={prefix}><Redirect to={`${prefix}${path.substring(1)}`} /></Route>);
        }
    });

    return routesList;
};

function CheckedRoute({ component: Component, componentConfig, path, accessRights, exact, ...rest }: { component: React.LazyExoticComponent<React.ComponentType<any>>, componentConfig: any, path: string, exact: boolean, accessRights: AuthLevelObject }) {
    const rightsCheck = useSelector((state: RootState) => rightsCheckSelector(state, { accessRights }));

    return (
        <Route
            path={path}
            render={({ location }) =>
                rightsCheck ?
                    <Component {...rest} exact={exact} config={componentConfig} />
                    :
                    <Redirect to={{
                        pathname: '/access-denied',
                        state: {
                            from: location,
                            title: 'Access Error',
                            message: 'Permission denied'
                        }
                    }} />
            }
        />
    );
};

const NotFound = ({ location }: { location: Location }) => {
    return (<div>{location.pathname} not found</div>);
};

export default function DashboardRoutes({ url }: { url: string }) {
    const excluded = useSelector((state: RootState) => state.mission.activeConfig?.layout?.["dashboard"]?.["exclude"] || []);
    const filteredRoutes = excludeRoutes(excluded, DASHBOARD_ROUTES) as DashboadRouteType[];
    const routes = useMemo(() => reduceRoutes(filteredRoutes, url), [url]);

    return (
        <Suspense fallback={<Loading text='Loading...' />}>
            <Switch>
                {routes}
                <Route component={NotFound} />
            </Switch>
        </Suspense>
    );
};