import NavigationIcon from '@mui/icons-material/Navigation';
import Tracking from "../components/navigation/tracking/Tracking";
import TrackingControl from "../components/navigation/tracking/Control";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import Geomarker from "../components/navigation/geomarker/Geomarker";
import Custom from '../components/navigation/custom/Custom';
import { CustomControl, CustomControlActions } from '../components/navigation/custom/Control';
import LayersIcon from '@mui/icons-material/Layers';
import { GeomarkerControl, GeomarkerControlActions } from "../components/navigation/geomarker/Control";
import Aero from "../components/navigation/aero/Aero";
import { AeroControl } from '../components/navigation/aero/Control';
import Weather from '../components/navigation/weather/Weather';
import { WeatherControl } from '../components/navigation/weather/Control';

export interface ServiceType {
    id: string;
    active: boolean;
    layer: {
        component: (props?: any) => JSX.Element | null;
        zIndex: number
    };
    control: {
        icon: any,
        component: (props?: any) => JSX.Element | null;
        actions?: (props?: any) => JSX.Element | null;
    };
};

export const NAVIGATION_SERVICES: ServiceType[] = [
    {
        id: "fleet",
        active: true,
        layer: {
            component: Tracking,
            zIndex: 400
        },
        control: {
            icon: NavigationIcon,
            component: TrackingControl,
        }
    },
    {
        id: "geomarker",
        active: true,
        layer: {
            component: Geomarker,
            zIndex: 200
        },
        control: {
            icon: LocationOnIcon,
            component: GeomarkerControl,
            actions: GeomarkerControlActions
        }
    },
    {
        id: "weather",
        active: true,
        layer: {
            component: Weather,
            zIndex: 100
        },
        control: {
            icon: ThunderstormIcon,
            component: WeatherControl
        }
    },
    {
        id: "aero",
        active: true,
        layer: {
            component: Aero,
            zIndex: 300
        },
        control: {
            icon: FlightTakeoffIcon,
            component: AeroControl,
        }
    },
    {
        id: "custom-layers",
        active: true,
        layer: {
            component: Custom,
            zIndex: 10
        },
        control: {
            icon: LayersIcon,
            component: CustomControl,
            actions: CustomControlActions
        }
    }
];