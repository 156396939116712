import React, { useState, useEffect } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import { useElementSize } from 'usehooks-ts';
import merge from "deepmerge";
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import { visuallyHidden } from '@mui/utils';
import StringUtils from '../../utils/StringUtils';
import { RootState } from '../../app/store';
import { hourOrNbDays, hour, toDate } from '../../utils/TimeUtils';
import { GROUND_PLID, IWG1_FIELDS } from '../../config/const';
import findLastIndex from 'lodash/findLastIndex';
import { telemetryActions } from '../../redux/telemetry/telemetrySlice';
import CustomControls, { ControlType } from './customControls/CustomControls';
import TelemetryWidgetDialog from './TelemetryWidgetDialog';

const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    flexGrow: 1
}));

const StyledFieldSet = styled('fieldset')(({ theme }) => ({
    userSelect: "none",
    border: '1px solid rgba(255,255,255,0.2)',
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
    cursor: 'pointer'
}));

const StyledInactiveFieldSet = styled('fieldset')(({ theme }) => ({
    userSelect: "none",
    border: '1px solid rgba(255,255,255,0.2)'
}));

const TELEMETRY_CUSTOM_CONTROLS: { [key: string]: ControlType } = {
    "dassault_top": { type: "dsslt_lwc_top", id: "top", title: "Top LWC_Zero", topicIn: "dassault/top/values", topicOut: "dassault/top/request", timeout: 5 }
};

type ColumnId = 'source' | 'label' | 'issued' | 'params' | 'info';

interface Column {
    id: ColumnId;
    label: string;
    minWidth?: number;
    maxWidth?: number;
    align?: 'right' | 'left';
    format?: (value: any, source: string, label: string, issued: string, openTimeSerie?: (source: string, label: string, param: string, unit: string, toFixed: number, active?: boolean) => void) => any;
};

export const COLUMNS: readonly Column[] = [
    {
        id: 'source',
        label: 'Source',
        minWidth: 80,
        format: (_params, source, _label, _issued, _openTimeSerie) => <SourceName source={source} />
    },
    { id: 'label', label: 'Label', minWidth: 80, align: 'left' },
    { id: 'issued', label: 'Issued', minWidth: 80, align: 'left' },
    {
        id: 'params',
        label: 'Params',
        minWidth: 80,
        align: 'left',
        format: (params, source, label, _issued, openTimeSerie) => <Params params={params} source={source} label={label} openTimeSerie={openTimeSerie} />
    }
];

export const MOBILE_COLUMNS: readonly Column[] = [
    {
        id: 'info',
        label: 'Info',
        format: (_params, source, label, issued, _openTimeSerie) => <ParamInfo issued={issued} source={source} label={label} />
    },
    {
        id: 'params',
        label: 'Params',
        align: 'left',
        format: (params, source, label, _issued, openTimeSerie) => <MobileParams params={params} source={source} label={label} openTimeSerie={openTimeSerie} />
    }
];

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
    columns: readonly Column[];
    order: Order;
    orderBy: string;
    rowCount: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: ColumnId) => void;
    isGround: boolean;
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

export const paramsSelector = createSelector(
    (state: RootState) => state.telemetry,
    (state: RootState) => state.replay.currentTime,
    (telemetry, currentTime) => {
        let to_return: any[] = [];
        const currentTS = toDate(currentTime).getTime() / 1000;
        Object.entries(telemetry.streams).forEach(([source, labels]) => {
            Object.entries(labels).forEach(([label, [timestamps, values]]) => {
                const lastIndex = currentTS ? findLastIndex(timestamps, (ts: number) => ts <= currentTS) : timestamps.length - 1;
                if (lastIndex >= 0) {
                    const last = values[lastIndex];
                    if (last) {
                        to_return.push({
                            source,
                            label,
                            issued: currentTS ? hour(timestamps[lastIndex]) : hourOrNbDays(timestamps[lastIndex]), // in replay mode (i.e. currentTS is defined, we show the hour)
                            params: Object.entries(last),
                            tx: telemetry.stopped[label] === undefined || telemetry.stopped[label] === false
                        });
                    } else {
                        console.error("Last is null. Should not happen");
                    };
                }
            })
        })
        return to_return;
    }
);

const sourcesSelector = createSelector(
    (state: RootState) => state.nav.tracking,
    (tracking) => {
        return Object.entries(tracking).map(([trackerId, tracker]) => {
            return ({
                id: trackerId,
                name: tracker.name
            })
        });
    }
);

function getParamConfig(config: any, name: string, label?: string) {

    if (label === "IWG1") {
        const p = IWG1_FIELDS.find((f: any) => f.name === name);
        return p;
    };

    if (config) {
        if (name in config) return config[name];

        const paramConfig = Object.values(config).find((c: any) => c.name === name);
        if (paramConfig) return paramConfig;
    };

    return null;
};

function getToFixed(value: any) {
    if (value === undefined) return 2;
    else if (value === 0) return undefined;
    else return value;
};

function EnhancedTableHead({ columns, order, orderBy, rowCount, onRequestSort, isGround }: EnhancedTableProps) {

    const createSortHandler = (property: ColumnId) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {columns.map((column) => (
                    <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{ minWidth: column.minWidth, maxWidth: column.maxWidth, height: '50px', backgroundColor: (theme) => theme.palette.primary.main }}
                        sortDirection={orderBy === column.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={createSortHandler(column.id)}
                        >
                            <Typography variant="body1" sx={{ fontSize: '1rem', whiteSpace: "nowrap" }}>{column.label}</Typography>
                            {orderBy === column.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {!isGround &&
                    <TableCell
                        align="right"
                        sx={{ minWidth: 50, height: '50px', backgroundColor: (theme) => theme.palette.primary.main }}
                    >
                        <Typography variant="body1" sx={{ fontSize: '1rem', whiteSpace: "nowrap" }}>Tx</Typography>
                    </TableCell>
                }
            </TableRow>
        </TableHead>
    );
};

const SourceName = React.memo(function SourceName({ source }: { source: string }) {
    const name = useSelector((state: RootState) => {
        if (!state.mission.active) return 'UNDEF';
        else return state.mission.allowed[state.mission.active].fleet.find((v: any) => v.id === source)?.name || source;
    });
    return (<Typography variant="body1" sx={{ fontSize: '1rem', whiteSpace: "nowrap", overflow: 'auto' }}>{name}</Typography>);
});

function sortParams(label: string, params: any) {

    switch (label) {
        case "IWG1":
            params.sort(function (a: any, b: any) {
                return (IWG1_FIELDS.findIndex((f => f.name === a[0])) > IWG1_FIELDS.findIndex((f => f.name === b[0])));
            });
            break;

        default:
            break;
    };

    return params;
};

function Params({ params, source, label, openTimeSerie }: { params: any, source: string, label: string, openTimeSerie?: (source: string, label: string, param: string, unit: string, toFixed: number, active?: boolean) => void }) {
    const config = useSelector((state: RootState) => {
        const cfg = merge(state.telemetry.config?.default || {}, state.telemetry.config?.[source] || {});
        return cfg?.streams?.[label];
    });

    return (
        <Grid container spacing={1}>
            {
                sortParams(label, params)
                    .filter(([key, _value]: [string, string]) => key !== "position")
                    .map(([key, value]: [string, string]) => {
                        let paramConfig = getParamConfig(config, key, label);
                        let toFixed = getToFixed(paramConfig?.toFixed);
                        let unit = paramConfig?.unit || "";
                        return (
                            <Grid key={key} item>
                                {openTimeSerie ?
                                    <StyledFieldSet
                                        sx={{
                                            borderLeft: `3px solid ${StringUtils.stringToColor(key)}`,
                                        }}
                                        onMouseDown={(event: any) => {
                                            if (event.button === 0) openTimeSerie(source, label, key, unit, toFixed, true);
                                            else if (event.button === 1) openTimeSerie(source, label, key, unit, toFixed, false);
                                        }}
                                    >
                                        <legend><Typography variant="subtitle2">{key}</Typography></legend>
                                        <Typography variant="body1" sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}>{value !== null && parseFloat(value).toFixed(toFixed)} {unit}</Typography>
                                    </StyledFieldSet>
                                :
                                    <StyledInactiveFieldSet
                                        sx={{
                                            borderLeft: `3px solid ${StringUtils.stringToColor(key)}`,
                                        }}
                                    >
                                        <legend><Typography variant="subtitle2">{key}</Typography></legend>
                                        <Typography variant="body1" sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}>{value !== null && parseFloat(value).toFixed(toFixed)} {unit}</Typography>
                                    </StyledInactiveFieldSet>
                                }
                            </Grid>
                        )
                    })
            }
        </Grid>
    );
};

function MobileParams({ params, source, label, openTimeSerie }: { params: any, source: string, label: string, openTimeSerie?: (source: string, label: string, param: string, unit: string, toFixed: number, active?: boolean) => void }) {
    const config = useSelector((state: RootState) => {
        const cfg = merge(state.telemetry.config?.default || {}, state.telemetry.config?.[source] || {});
        return cfg?.streams?.[label];
    });

    return (
        <Stack direction="column" spacing={0} sx={{ maxHeight: 200, overflow: 'auto' }}>
            {
                sortParams(label, params)
                    .filter(([key, _value]: [string, string]) => key !== "position")
                    .map(([key, value]: [string, string]) => {
                        let paramConfig = getParamConfig(config, key, label);
                        let toFixed = getToFixed(paramConfig?.toFixed);
                        let unit = paramConfig?.unit || "";
                        return (
                            <Stack key={key} direction="row" spacing={1}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: StringUtils.stringToColor(key),
                                        fontSize: '1rem',
                                        whiteSpace: 'nowrap',
                                        cursor: 'pointer'
                                    }}
                                    onMouseDown={openTimeSerie ?
                                        (event: any) => {
                                            if (event.button === 0) openTimeSerie(source, label, key, unit, toFixed, true);
                                            else if (event.button === 1) openTimeSerie(source, label, key, unit, toFixed, false);
                                        }
                                    : () => {}}
                                >{key}:</Typography>
                                <Typography variant="body1" sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}>{parseFloat(value).toFixed(toFixed)} {unit}</Typography>
                            </Stack>
                        )
                    })
            }
        </Stack>
    );
};

const ParamInfo = React.memo(function ParamInfo({ issued, source, label }: { issued: string, source: string, label: string }) {
    const name = useSelector((state: RootState) => {
        if (!state.mission.active) return 'UNDEF';
        else return state.mission.allowed[state.mission.active].fleet.find((v: any) => v.id === source)?.name || source;
    });

    return (
        <Stack direction="column" spacing={0}>
            <Typography variant="body1" sx={{ fontSize: '1rem', whiteSpace: "nowrap", overflow: 'auto' }}>{name}</Typography>
            <Typography variant="body1" sx={{ fontSize: '1rem', whiteSpace: "nowrap", overflow: 'auto' }}>{label}</Typography>
            <Typography variant="body1" sx={{ fontSize: '1rem', whiteSpace: "nowrap", overflow: 'auto' }}>{issued}</Typography>
        </Stack>
    );

});

export function ParamsTable({ openTimeSerie, columns, maxHeight, params }: { openTimeSerie: (source: string, label: string, param: string, unit: string, toFixed: number, active?: boolean) => void, columns: readonly Column[], maxHeight: number, params: any[] }) {
    const dispatch = useDispatch();
    const confirm = useConfirm();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<ColumnId>('source');
    const isAdmin = useSelector((state: RootState) => state.auth.level === "superuser" || state.auth.level === "admin");
    const myPlid = useSelector((state: RootState) => state.auth.myPlid);
    const computed = useSelector((state: RootState) => Object.values(state.telemetry.computed[myPlid || ""] || {}).map(element => element.label));
    const positionSources = useSelector((state: RootState) => state.telemetry.labels[myPlid || ""]?.filter((l: any) => l.is_source).map(element => element.label) || []);
    const isGround = GROUND_PLID === myPlid;

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: ColumnId) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleStopClick = (label: string, isStopped: boolean, isPositionSource: boolean) => {
        if (isPositionSource && !isStopped)
            confirm({
                confirmationButtonProps: { color: "inherit" },
                cancellationButtonProps: { color: "inherit" },
                description: `Disabling transmission of a position source may cause loss of this aircraft's position tracking on other Planet instances.`
            }).then(() => {
                dispatch(telemetryActions.update_stop({ label, stop: !isStopped }));
            }).catch(() => { });
        else
            dispatch(telemetryActions.update_stop({ label, stop: !isStopped }));
    };

    return (
        <Box sx={{ width: '100%', height: '100%', overflow: 'hidden' }}
        >
            <TableContainer
                sx={{
                    maxHeight,
                    userSelect: "text"
                }}
            >
                <Table stickyHeader size="small">
                    <EnhancedTableHead
                        columns={columns}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={params.length}
                        isGround={isGround}
                    />
                    <TableBody>
                        {params.sort(getComparator(order, orderBy)).slice()
                            .map((row: any) => {
                                const isFromMe = row.source === myPlid;
                                let isPositionSource = false;
                                let isComputed = false
                                let isStopped = false;
                                if (isFromMe) {
                                    isPositionSource = positionSources.includes(row.label);
                                    isComputed = computed.includes(row.label);
                                    isStopped = !row.tx;
                                }
                                return (
                                    <TableRow role="checkbox" tabIndex={-1} key={`${row.source}-${row.label}`} sx={{ backgroundColor: (theme) => theme.palette.primary.dark }}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} sx={{ minWidth: column.minWidth, maxWidth: column.maxWidth }} align={column.align}>
                                                    {column.format
                                                        ? column.format(value, row.source, row.label, row.issued, openTimeSerie)
                                                        :
                                                        <Typography variant="body1" sx={{ fontSize: '1rem', whiteSpace: "nowrap", overflow: 'auto' }}>{value}</Typography>
                                                    }
                                                </TableCell>
                                            );
                                        })}
                                        {!isGround &&
                                            <TableCell align="right">
                                                {isFromMe ?
                                                    isAdmin && !isComputed ?
                                                        <Button
                                                            variant="outlined"
                                                            color={isStopped ? "error" : "info"}
                                                            onClick={() => handleStopClick(row.label, isStopped, isPositionSource)} disabled={!isAdmin || isComputed}
                                                        >
                                                            <Typography variant="body1" sx={{ fontSize: '1rem', whiteSpace: "nowrap" }}>
                                                                {isComputed ? "N/A" : isStopped ? "Start" : "Stop"}
                                                            </Typography>
                                                        </Button>
                                                        :
                                                        <Typography variant="body1" sx={{ fontSize: '1rem', whiteSpace: "nowrap" }} color={isStopped ? "#ff3333" : "#03b1f5"}>
                                                            {isComputed ? "N/A" : isStopped ? "Stopped" : "Live"}
                                                        </Typography>
                                                    :
                                                    null
                                                }
                                            </TableCell>
                                        }
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default function TelemetryListTab({ openTimeSerie, filters, setFilters, maxHeight }: { openTimeSerie: (source: string, label: string, param: string, unit: string, toFixed: number, active?: boolean) => void, filters: any, setFilters: (filters: any) => void, maxHeight: number }) {
    const mobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
    const controls: any[] = useSelector((state: RootState) => {
        let to_return: any[] = []
        let myplid: any = state.auth.myPlid;
        let controlKeys: any[] = merge(state.mission.activeConfig?.["telemetry"]?.default?.["controls"] || [], state.mission.activeConfig?.["telemetry"]?.[myplid]?.["controls"] || []);
        controlKeys.forEach((key: string) => {
            if (TELEMETRY_CUSTOM_CONTROLS[key] !== undefined) to_return.push(TELEMETRY_CUSTOM_CONTROLS[key]);
        });
        return to_return;
    });
    const availableSources: any[] = useSelector(sourcesSelector);
    const availableParams: any[] = useSelector(paramsSelector);
    const [params, setParams] = useState<any[]>([]);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [labelOptions, setLabelOptions] = useState<any[]>([]);
    const [ref, { height }] = useElementSize();

    const onLabelsChange = (event: React.SyntheticEvent, value: any, reason: string) => {
        setFilters({ ...filters, labels: value });
    };

    const onSourcesChange = (event: React.SyntheticEvent, value: any, reason: string) => {
        setFilters({ ...filters, sources: value });
    };

    const openWidgetDialog = () => {
        setDialogOpen(true);
    };

    useEffect(() => {
        let newParams = availableParams;
        if (filters.sources.length > 0) newParams = newParams.filter((p: any) => filters.sources.map((s: any) => s.id).includes(p.source));
        if (filters.labels.length > 0) newParams = newParams.filter((p: any) => filters.labels.includes(p.label));
        setParams(newParams);
    }, [availableParams, availableSources, filters]);

    useEffect(() => {
        let opts = availableParams.map((p: any) => p.label);
        let uniqueOpts = opts.filter((element, index) => {
            return opts.indexOf(element) === index;
        });
        setLabelOptions(uniqueOpts);
    }, [availableParams]);

    return (
        <StyledCard>
            <CardHeader
                ref={ref}
                title={mobile ? null : <Typography variant="h6">Telemetry Streams</Typography>}
                action={
                    <Stack direction="row" spacing={1} alignItems="center">
                        <CustomControls controls={controls} />
                        <Button variant="contained" onClick={openWidgetDialog} startIcon={<WebAssetIcon />}>
                            ADD WIDGET
                        </Button>
                        <FormControl sx={{ m: 1, minWidth: mobile ? 150 : 200, maxWidth: mobile ? 150 : undefined }}>
                            <Autocomplete
                                fullWidth
                                size="small"
                                multiple
                                options={availableSources}
                                filterSelectedOptions
                                value={filters.sources}
                                onChange={onSourcesChange}
                                getOptionLabel={(option: any) => option.name}
                                renderTags={(value: any[], getTagProps) =>
                                    value.map((option: any, index: number) => (
                                        <Chip variant="outlined" size="small" label={option.name} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(sources) => (
                                    <TextField
                                        {...sources}
                                        label="Filter Sources"
                                        placeholder="Sources"
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: mobile ? 150 : 200, maxWidth: mobile ? 150 : undefined }}>
                            <Autocomplete
                                fullWidth
                                size="small"
                                multiple
                                options={labelOptions}
                                filterSelectedOptions
                                value={filters.labels}
                                onChange={onLabelsChange}
                                renderTags={(value: string[], getTagProps) =>
                                    value.map((option: string, index: number) => (
                                        <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Filter Labels"
                                        placeholder="Labels"
                                    />
                                )}
                            />
                        </FormControl>
                    </Stack>
                }
            />
            <CardContent sx={{ padding: 0, height: '100%' }}>
                <ParamsTable openTimeSerie={openTimeSerie} columns={mobile ? MOBILE_COLUMNS : COLUMNS} maxHeight={maxHeight - height} params={params} />
            </CardContent>
            <TelemetryWidgetDialog open={dialogOpen} setOpen={setDialogOpen}/>
        </StyledCard>
    );
};