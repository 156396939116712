import React, { useCallback } from "react";
import { RFeature, RLayerVectorImage } from "rlayers";
import { transform } from "ol/proj";
import { LineString } from "ol/geom";
import VectorLayer from '../../map/layers/VectorLayer';
import { COLOR_TRACKING_FUNCTIONS, COLOR_TRACK_NONE } from '../../../../config/tracking';
import { trackShadowStyle } from "../styles/track";
import { store } from "../../../../app/store";
import { TELEMETRY_GAP } from '../../../../config/const';
import isEqual from "lodash/isEqual";

const Vector = React.memo(function Vector({ openPoint, closePoint, properties }: { openPoint: any, closePoint: any, properties: any }) {
    if (!closePoint) return null;
    if (closePoint.timestamp - openPoint.timestamp > TELEMETRY_GAP) return null;
    const mapProjection = store.getState().map.projection;
    const geometry = new LineString([transform(openPoint.position, "EPSG:4326", mapProjection), transform(closePoint.position, "EPSG:4326", mapProjection)]);

    return (
        <RFeature
            geometry={geometry}
            properties={properties}
        />
    );
}, isEqual);


const Track = React.memo(function Track({ positions, color }: { positions: any, color?: string }) {
    const mapProjection = store.getState().map.projection;
    const geometry = new LineString(positions[1].map((p: any) => {
        return (transform(p.position, "EPSG:4326", mapProjection));
    }));

    return (<RFeature geometry={geometry} properties={{ color }} />);
}, isEqual);

export default function TrackLayer(props: any) {
    const { id, positions, shadow, length, color, colorTrack, ...rest }: { id: string, positions: any[], shadow: any[], length: number, color: string, colorTrack: string } = props;

    const style = useCallback((feature: any, resolution: any) => {
        return COLOR_TRACKING_FUNCTIONS[colorTrack].styleFunc(feature, resolution);
    }, [colorTrack]);

    return (
        <>
            <VectorLayer
                id={`${id}-track-layer`}
                component={RLayerVectorImage}
                style={style}
                {...rest}
            >
                {
                    colorTrack === COLOR_TRACK_NONE ?
                        <Track positions={positions} color={color} />
                        :
                        <>
                            {
                                positions[0].map((timestamp: number, i: number) => {
                                    if (i === positions[0].length - 1) return null;
                                    const { position, ...properties } = positions[1][i];
                                    return (<Vector
                                        key={timestamp}
                                        openPoint={{ position, timestamp: positions[0][i] }}
                                        closePoint={{ position: positions[1][i + 1].position, timestamp: positions[0][i + 1] }}
                                        properties={{ ...properties, timestamp }}
                                    />);
                                })
                            }
                        </>
                }
            </VectorLayer>
            <VectorLayer
                id={`${id}-track-shadow-layer`}
                component={RLayerVectorImage}
                style={trackShadowStyle}
                {...rest}
            >
                <Track positions={shadow} />
            </VectorLayer>
        </>
    );
};