
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import { get as getProjection, transformExtent } from "ol/proj";
import { store } from "../app/store";

function isNumber(value: any) {
    return typeof value === 'number';
};

export const AVAILABLE_COORDINATES_SYNTAXES = [
    {
        id: 'signed-degree-decimal',
        name: 'Degrees'
    },
    {
        id: 'cardinal-degree-minute-decimal',
        name: 'Degrees minutes decimals'
    },
    {
        id: 'cardinal-degree-minute-second-decimal',
        name: 'Degrees minutes seconds decimals'
    }
];

let ls_map_json = JSON.parse(localStorage.getItem('_map') || '{}');
let ls_proj = ls_map_json.projection || 'EPSG:3857';

if (!(['EPSG:4326', 'EPSG:3857', 'EPSG:3575'].includes(ls_proj))) ls_proj = 'EPSG:3857';
export const MAP_PROJECTION = ls_proj;

export const MAP_BACKGROUND = !ls_map_json.background || isNumber(ls_map_json.background) ? "basic" : ls_map_json.background; // checking isNumber for backward compat as background was previously stored as integer

let ls_graticule = (ls_map_json.graticule === true);
export const MAP_GRATICULE = ls_graticule;

let ls_enable_gm_measures = (ls_map_json.enable_gm_measures === true);
export const MAP_ENABLE_GM_MEASURES = ls_enable_gm_measures;

let ls_coordinates_syntax = ls_map_json.coordinates_syntax || AVAILABLE_COORDINATES_SYNTAXES[0].id;
if (!(AVAILABLE_COORDINATES_SYNTAXES.map(c => c.id).includes(ls_coordinates_syntax))) ls_coordinates_syntax = AVAILABLE_COORDINATES_SYNTAXES[0].id;
export const MAP_COORDINATES_SYNTAX = ls_coordinates_syntax;

let extent = null;
let zoom = 4;
let center = [2, 42];
let rotation = 0;
if (ls_map_json.view) {
    if ('extent' in ls_map_json.view) extent = ls_map_json.view.extent;
    if ('zoom' in ls_map_json.view) zoom = ls_map_json.view.zoom;
    if ('center' in ls_map_json.view) center = ls_map_json.view.center;
    if ('rotation' in ls_map_json.view) rotation = ls_map_json.view.rotation;
};
export const MAP_VIEW = { extent, zoom, center, rotation };

export const MAP_MAX_ZOOMLEVEL = 14;

export const MAP_LOCAL_ORIGIN = ls_map_json.localOrigin || { lon: 0, lat: 0, alt: 0, show_layer: false };

export const PROJ_DEFS: { [key: string]: any } = {
    "EPSG:3575": {
        def:
            "+proj=laea +lat_0=90 +lon_0=10 +x_0=0 +y_0=0 +ellps=WGS84 +datum=WGS84 +units=m +no_defs",
        world_extent: [-180, 0, 180, 90]
    }
};

Object.keys(PROJ_DEFS).forEach(proj_name => {
    proj4.defs(proj_name, PROJ_DEFS[proj_name].def);
    register(proj4);
    const p: any = getProjection(proj_name);
    let extent = transformExtent(
        PROJ_DEFS[proj_name].world_extent,
        "EPSG:4326",
        p,
        4
    );
    p.setExtent(extent);
    p.setWorldExtent(PROJ_DEFS[proj_name].world_extent);
});

export function isBackgroundDark() {
    const name = store.getState().map.background;
    const backgrounds = store.getState().map.available_backgrounds;

    const background = backgrounds.find((background) => background.name === name);

    return background?.dark;
};