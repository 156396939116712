import axios from "axios";
import { API_CONFIG } from '../../config/api';

export const TRANSFER_API = {
    getTransfers: (token: string, missionId: string) => axios.get(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/transfer/${missionId}/?duration=12h`, //12h since last, not 12h since now
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
    postTransfer: (token: string, missionId: string, file: any, destination: string) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("dst", destination);
        return axios.post(
            `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/transfer/${missionId}/upload/`,
            formData,
            { headers: { "Content-type": "multipart/form-data", Authorization: "Token " + token } }
        );
    },
    updateTransfer: (token: string, missionId: string, transferId: string, status: "published" | "stopped") => {
        return axios.patch(
            `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/transfer/${missionId}/${transferId}/`,
            {"status": status},
            { headers: {"Content-type": "application/json", Authorization: "Token " + token } }
        );
    }
};