import React from "react";
import Box from "@mui/material/Box";
import DassaultLwcTop from "./DassaultLwcTop";

export interface ControlType {
    type: string,
    id: string,
    title: string,
    topicIn: string,
    topicOut: string,
    timeout: number
};

export default function CustomControls({ controls }: { controls: ControlType[] }) {

    if (!Array.isArray(controls) || controls.length === 0) return null;

    return (
        <Box display="flex">
            {
                controls.map(c => {
                    switch (c.type) {

                        case "dsslt_lwc_top":
                            return <DassaultLwcTop key={c.id} {...c} />

                        default:
                            return null;
                    }
                })
            }
        </Box>
    );
};