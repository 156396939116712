import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RFeature } from "rlayers";
import VectorLayer from "../../map/layers/VectorLayer";
import { Point } from "ol/geom";
import { transform } from "ol/proj";
import { vehicleStyle } from '../styles/vehicle';
import { store } from "../../../../app/store";
import { trackingActions } from "../../../../redux/tracking/trackingSlice";
import { toDate } from "../../../../utils/TimeUtils";
import { RootState } from "../../../../app/store";
import isEqual from "lodash/isEqual";
import findLastIndex from 'lodash/findLastIndex';

export const dataSelector = createSelector(
    (state: RootState) => state.telemetry.streams,
    (state: RootState) => state.telemetry.computed,
    (_: RootState, properties: { id: string }) => properties,
    (streams, computed, { id }) => [streams[id], computed[id]]
);

const Vehicle = React.memo(function Vehicle({ id, hud, follow, position, properties }: { id: string, hud: any, follow: boolean, position: any, properties: any }) {
    const dispatch = useDispatch();
    const mapProjection = store.getState().map.projection;
    const geometry = new Point(transform(position, "EPSG:4326", mapProjection));

    const toggleHUD = useCallback(() => {
        hud.shown ? dispatch(trackingActions.hide_hud({ plid: id })) : dispatch(trackingActions.show_hud({ plid: id }));
        return false; // prevent forwarding click event to other features
    }, [dispatch, hud.shown, id]);

    const onDblClick = useCallback(() => {
        dispatch(trackingActions.set_follow({ plid: id, follow: !follow }));
        return false; // prevent zooming in on double click
    }, [dispatch, follow, id]);

    return (
        <RFeature
            geometry={geometry}
            properties={properties}
            onSingleClick={toggleHUD}
            onDblClick={onDblClick}
        />
    );
}, isEqual);

export default function VehicleLayer(props: any) {
    const { id, status, visible, follow, last, zIndex, hud, color, kind, virtual, name, map } = props;
    const style = useCallback((feature: any, resolution: any) => vehicleStyle(feature, resolution, hud, follow), [hud, follow]);
    const currentTime = useSelector((state: RootState) => state.replay.currentTime);
    const currentTS = toDate(currentTime).getTime() / 1000;
    // If we don't use a selector, the hud is not updated when the origin is moved
    const [streams, computed] = useSelector((state: RootState) => dataSelector(state, { id }));

    useEffect(() => {
        if (last && visible && follow) {
            map.getView().setCenter(transform(last.position, "EPSG:4326", store.getState().map.projection));
        }
    }, [last, visible, follow]);

    if (!last) return null;

    const computedNames = computed ? Object.entries(computed).map(([name, module]) => module.label) : undefined;
    const computedStreams = streams && computedNames ? Object.entries(streams).filter(([name, values]) => computedNames.includes(name)) : [];
    computedStreams.forEach(([name, [timestamps, values]]) => {
        const lastIndex = currentTS ? findLastIndex(timestamps, (ts: number) => ts <= currentTS) : timestamps.length - 1;
        last.properties = { ...last.properties, ...values[lastIndex] };
    });

    return (
        <VectorLayer
            id={`${id}-vehicle-layer`}
            zIndex={zIndex}
            visible={visible}
            style={style}
            rulingSnap={true}
        >
            <Vehicle
                id={id}
                hud={hud}
                follow={follow}
                position={last.position}
                properties={{ ...last.properties, plid: id, status, color, kind, virtual, name }}
            />
        </VectorLayer>
    );
};