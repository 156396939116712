import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import Switch from '@mui/material/Switch';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import TextField from "@mui/material/TextField";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LensIcon from '@mui/icons-material/Lens';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { RootState } from "../../../app/store";
import { aeroActions } from "../../../redux/aero/aeroSlice";
import { Accordion, AccordionSummary, AccordionDetails } from '../../utils/accordion/Accordion';
import { AIRPORTS_MIN_ZOOM_LEVEL, AIRPORT_TYPES, NAVAIDS_MIN_ZOOM_LEVEL, NAVAID_TYPES } from '../../../config/aero';
import { OPERATORS } from './toasts/AdsbToast';
import { ColorPalette } from '../../telemetry/timeSeriesTab/Side';

function Airports() {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);
    const [info, setInfo] = useState<string | null>(null);
    const airports = useSelector((state: RootState) => state.nav.aero.airports);
    const zoom = useSelector((state: RootState) => state.map.view.zoom);

    const onChange = useCallback(() => {
        setExpanded(expanded => !expanded);
    }, []);

    const onSwitch = useCallback((e: any) => {
        dispatch(aeroActions.set_airports_visibility({ visible: e.target.checked }))
    }, [dispatch]);

    const onClick = (e: any) => {
        e.stopPropagation();
    };

    const onCheckedChange = (e: any) => {
        if (e.target.checked) dispatch(aeroActions.add_airport_type({ type: e.target.value }));
        else dispatch(aeroActions.remove_airport_type({ type: e.target.value }));
    };

    useEffect(() => {
        if (airports.visible) {
            if (zoom < AIRPORTS_MIN_ZOOM_LEVEL) setInfo('Zoom in to display Airports');
            else setInfo(null);
        } else setInfo(null);
    }, [airports.visible, zoom]);

    return (
        <Accordion expanded={expanded} onChange={onChange} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary>
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                >
                    <Typography variant="h6" sx={{ fontSize: '1rem' }}>Airports</Typography>
                    <Typography variant="caption" sx={{ color: (theme) => theme.palette.warning.main }}>{info}</Typography>
                    <Tooltip title={`${airports.visible ? "Hide" : "Show"} on map`} placement="right">
                        <Switch
                            size="small"
                            checked={airports.visible}
                            onChange={onSwitch}
                            onClick={onClick}
                            color="secondary"
                        />
                    </Tooltip>
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ width: '100%', maxHeight: '250px', overflow: 'auto', padding: 1 }}>
                <FormControl sx={{ width: '100%' }} component="fieldset" variant="standard" focused={true} color='secondary'>
                    <FormLabel component="legend">Select displayed Airports types:</FormLabel>
                    <FormGroup row color='secondary'>
                        {
                            AIRPORT_TYPES.map((t: string) =>
                                <FormControlLabel
                                    key={t}
                                    control={
                                        <Checkbox
                                            color='secondary'
                                            checked={airports.displayedTypes.indexOf(t) > -1}
                                            onChange={onCheckedChange}
                                            value={t}
                                        />
                                    }
                                    label={t}
                                />
                            )
                        }
                    </FormGroup>
                </FormControl>
            </AccordionDetails>
        </Accordion>
    );
};

function Navaids() {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);
    const [info, setInfo] = useState<string | null>(null);
    const navaids = useSelector((state: RootState) => state.nav.aero.navaids);
    const zoom = useSelector((state: RootState) => state.map.view.zoom);

    const onChange = useCallback(() => {
        setExpanded(expanded => !expanded);
    }, []);

    const onSwitch = useCallback((e: any) => {
        dispatch(aeroActions.set_navaids_visibility({ visible: e.target.checked }))
    }, [dispatch]);

    const onClick = (e: any) => {
        e.stopPropagation();
    };

    const onCheckedChange = (e: any) => {
        if (e.target.checked) dispatch(aeroActions.add_navaid_type({ type: e.target.value }));
        else dispatch(aeroActions.remove_navaid_type({ type: e.target.value }));
    };

    useEffect(() => {
        if (navaids.visible) {
            if (zoom < NAVAIDS_MIN_ZOOM_LEVEL) setInfo('Zoom in to display Navaids');
            else setInfo(null);
        } else setInfo(null);
    }, [navaids.visible, zoom]);

    return (
        <Accordion expanded={expanded} onChange={onChange} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary>
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                >
                    <Typography variant="h6" sx={{ fontSize: '1rem' }}>Navaids</Typography>
                    <Typography variant="caption" sx={{ color: (theme) => theme.palette.warning.main }}>{info}</Typography>
                    <Tooltip title={`${navaids.visible ? "Hide" : "Show"} on map`} placement="right">
                        <Switch
                            size="small"
                            checked={navaids.visible}
                            onChange={onSwitch}
                            onClick={onClick}
                            color="secondary"
                        />
                    </Tooltip>
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ width: '100%', maxHeight: '250px', overflow: 'auto', padding: 1 }}>
                <FormControl sx={{ width: '100%' }} component="fieldset" variant="standard" focused={true} color='secondary'>
                    <FormLabel component="legend">Select displayed Navaid types:</FormLabel>
                    <FormGroup row color='secondary'>
                        {
                            NAVAID_TYPES.map((t: string) =>
                                <FormControlLabel
                                    key={t}
                                    control={
                                        <Checkbox
                                            color='secondary'
                                            checked={navaids.displayedTypes.indexOf(t) > -1}
                                            onChange={onCheckedChange}
                                            value={t}
                                        />
                                    }
                                    label={t}
                                />
                            )
                        }
                    </FormGroup>
                </FormControl>
            </AccordionDetails>
        </Accordion>
    );
};

function Adsb() {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);
    const [modifiedIndex, setIndex] = useState<number | null>(null);
    const [modifiedFilter, setFilter] = useState<any | null>(null);
    const adsb = useSelector((state: RootState) => state.nav.aero.adsb);

    const onChange = useCallback(() => {
        setExpanded(expanded => !expanded);
    }, []);

    const onSwitch = useCallback((e: any) => {
        dispatch(aeroActions.set_adsb_visibility({ visible: e.target.checked }))
    }, [dispatch]);

    const onClick = (e: any) => {
        e.stopPropagation();
    };

    const handleChange = useCallback((key: string, value: any) => {
        setFilter((filter: any) => ({
            ...filter,
            [key]: value
        }));
    }, [setFilter]);

    const moveUp = useCallback((index: number) => {
        if (modifiedIndex !== null) {
            if (index === modifiedIndex) setIndex(index - 1);
            else if (index === modifiedIndex + 1) setIndex(index);
        }
        dispatch(aeroActions.moveup_adsb_filter({ index }));
    }, [dispatch, modifiedIndex, setIndex]);

    const moveDown = useCallback((index: number) => {
        if (modifiedIndex !== null) {
            if (index === modifiedIndex) setIndex(index + 1);
            else if (index === modifiedIndex - 1) setIndex(index);
        }
        dispatch(aeroActions.movedown_adsb_filter({ index }));
    }, [dispatch, modifiedIndex, setIndex]);

    const startModify = useCallback((index: number) => {
        setIndex(index);
        setFilter(adsb.filters[index]);
    }, [setIndex, setFilter, adsb.filters]);

    const stopModify = useCallback(() => {
        setIndex(null);
        setFilter(null);
    }, []);

    const commitModify = useCallback((e: any) => {
        dispatch(aeroActions.modify_adsb_filter({ index: modifiedIndex, filter: modifiedFilter }));
        stopModify();
    }, [dispatch, modifiedIndex, modifiedFilter, stopModify]);

    const onDelete = useCallback((index: number) => {
        dispatch(aeroActions.delete_adsb_filter({ index }));
    }, [dispatch]);

    return (
        <Accordion expanded={expanded} onChange={onChange} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary>
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                >
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="flex-start"
                    >
                        <Typography variant="h6" sx={{ fontSize: '1rem' }}>Adsb</Typography>
                        {adsb.source && <Typography variant="h6" sx={{ fontSize: '0.8rem' }}>{`(source: ${adsb.source})`}</Typography>}
                    </Stack>
                    <Tooltip title={`${adsb.visible ? "Hide" : "Show"} on map`} placement="right">
                        <Switch
                            size="small"
                            checked={adsb.visible}
                            onChange={onSwitch}
                            onClick={onClick}
                            color="secondary"
                        />
                    </Tooltip>
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ width: '100%', maxHeight: '250px', overflow: 'auto', padding: 0 }}>
                <List
                    dense
                    subheader={
                        <ListSubheader sx={{ lineHeight: '20px' }}>
                            <Typography variant="overline" style={{ fontSize: "0.75rem" }}>
                                Filters
                            </Typography>
                        </ListSubheader>
                    }
                >
                    <>
                        <Divider variant="middle" />
                        {
                            adsb.filters.map((filter, index) => (
                                <ListItem key={index}>
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        justifyContent="space-between"
                                        alignItems="center"
                                        sx={{ width: '100%' }}
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="flex-start"
                                        >
                                            <div>{filter.key}</div>
                                            {typeof filter.value !== "boolean" && index !== modifiedIndex && <div>{filter.operator}</div>}
                                            {typeof filter.value !== "boolean" && index === modifiedIndex &&
                                                <TextField
                                                    select
                                                    value={modifiedFilter.operator}
                                                    inputProps={{ name: "operator" }}
                                                    onChange={(e: any) => handleChange(e.target.name, e.target.value)}
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{
                                                        minWidth: 60
                                                    }}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                >
                                                    {OPERATORS[typeof filter.value].map(
                                                        (operator: any, i: number) => {
                                                            return (
                                                                <option value={operator} key={i}>
                                                                    {operator}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </TextField>
                                            }
                                            {typeof filter.value === "boolean" && index === modifiedIndex && <Checkbox name="value" checked={filter.value} onChange={(e: any) => handleChange(e.target.name, e.target.checked)} />}
                                            {typeof filter.value === "boolean" && index !== modifiedIndex && <Checkbox name="value" checked={filter.value} />}
                                            {typeof filter.value !== "boolean" && index === modifiedIndex &&
                                                <TextField
                                                    id="value"
                                                    name="value"
                                                    type={typeof modifiedFilter.value}
                                                    value={modifiedFilter.value}
                                                    size="small"
                                                    onChange={(event: any) =>
                                                        handleChange(event.target.name, typeof modifiedFilter.value === "number" ? parseFloat(event.target.value) : event.target.value)
                                                    }
                                                />
                                            }
                                            {typeof filter.value !== "boolean" && index !== modifiedIndex && <div>{filter.value}</div>}
                                        </Stack>
                                        {
                                            index === modifiedIndex ?
                                                <ColorPalette color={modifiedFilter.color} size="small" onChange={(color: any) => handleChange("color", color)} />
                                                :
                                                <LensIcon sx={{ color: filter.color }} />
                                        }
                                        <Stack
                                            direction="row"
                                            spacing={0}
                                            alignItems="center"
                                            justifyContent="flex-end"
                                        >
                                            <Tooltip title="Move up" placement="top">
                                                <span>
                                                    <IconButton
                                                        size="small"
                                                        disabled={index === 0}
                                                        onClick={() => moveUp(index)}
                                                    >
                                                        <ArrowUpwardIcon fontSize="inherit" />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            <Tooltip title="Move down" placement="top">
                                                <span>
                                                    <IconButton
                                                        size="small"
                                                        disabled={index === adsb.filters.length - 1}
                                                        onClick={() => moveDown(index)}
                                                    >
                                                        <ArrowDownwardIcon fontSize="inherit" />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            {
                                                index === modifiedIndex ?
                                                    <Tooltip title="Confirm changes" placement="top">
                                                        <IconButton
                                                            size="small"
                                                            onClick={commitModify}
                                                        >
                                                            <CheckIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    :
                                                    <Tooltip title="Edit filter" placement="top">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => startModify(index)}
                                                        >
                                                            <EditIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </Tooltip>
                                            }
                                            {
                                                index === modifiedIndex ?
                                                    <Tooltip title="Cancel changes" placement="top">
                                                        <IconButton
                                                            size="small"
                                                            onClick={stopModify}
                                                        >
                                                            <ClearIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    :
                                                    <Tooltip title="Delete filter" placement="top">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => onDelete(index)}
                                                        >
                                                            <DeleteIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </Tooltip>
                                            }
                                        </Stack>
                                    </Stack>
                                </ListItem>
                            ))
                        }
                    </>
                </List>
            </AccordionDetails>
        </Accordion>
    );
};

export function AeroControl(props: any) {

    return (
        <Stack direction="column" spacing={1}>
            <Airports />
            <Navaids />
            <Adsb />
        </Stack>
    );
}
