import { GridLayoutType } from "../redux/widget/widgetSlice";
import TimeSeriesWidget from '../components/telemetry/timeSeriesTab/Widget';
import ChatWidget from '../components/chat/Widget';
import AttitudeWidget from "../components/navigation/telemetry/flight-indicators/img/Widget";
import TelemetryWidget from "../components/telemetry/Widget";

export interface WidgetConfigType {
    [key: string]: {
        component: (props?: any) => JSX.Element | null;
        gridLayout: GridLayoutType,
        gridLayout_md: GridLayoutType
    }
};

export const NAVIGATION_GRID_ID = 'navigation-grid';

export const TIMESERIES_WIDGET_ID = 'timeseries';
export const CHAT_WIDGET_ID = 'chat';
export const ATTITUDE_WIDGET_ID = 'attitude';
export const TELEMETRY_WIDGET_ID = 'telemetry';

export const DEFAULT_WIDGET_CONFIG: WidgetConfigType = {
    [TIMESERIES_WIDGET_ID]: {
        component: TimeSeriesWidget,
        gridLayout: { x: 10, y: 0, w: 18, h: 12, minW: 6, minH: 6, maxH: 24 },
        gridLayout_md: { x: 5, y: 0, w: 15, h: 10, minW: 5, minH: 6, maxH: 20 },
    },
    [CHAT_WIDGET_ID]: {
        component: ChatWidget,
        gridLayout: { x: 0, y: 0, w: 6, h: 18, minW: 5, minH: 8, maxH: 24 },
        gridLayout_md: { x: 0, y: 0, w: 5, h: 15, minW: 5, minH: 8, maxH: 20 },
    },
    [ATTITUDE_WIDGET_ID]: {
        component: AttitudeWidget,
        gridLayout: { x: 20, y: 0, w: 4, h: 8, minW: 3, minH: 8, maxH: 24 },
        gridLayout_md: { x: 15, y: 0, w: 5, h: 8, minW: 3, minH: 8, maxH: 20 },
    },
    [TELEMETRY_WIDGET_ID]: {
        component: TelemetryWidget,
        gridLayout: { x: 10, y: 0, w: 10, h: 10, minW: 4, minH: 6, maxH: 24 },
        gridLayout_md: { x: 5, y: 0, w: 8, h: 8, minW: 4, minH: 6, maxH: 20 }
    }
};