import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useElementSize } from 'usehooks-ts';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { widgetActions } from "../../redux/widget/widgetSlice";

export const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
    padding: theme.spacing(1),
    '& .MuiCardHeader-content': {
        overflow: 'hidden'
    },
    '& .MuiCardHeader-title': {
        fontSize: '1.2rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));

export default function WidgetTemplate(props: any) {
    const { id, gridId, header, actions, children, width, height, reduced } = props;
    const dispatch = useDispatch();
    const actionRef = useRef<any>(null);
    const [headerRef, { height: headerHeight }] = useElementSize();
    const [delayed, setDelayed] = useState(false);

    const onDoubleClick = () => {
        if (reduced) dispatch(widgetActions.expand_widget({ gridId, id }));
        else dispatch(widgetActions.reduce_widget({ gridId, id }));
    };

    useEffect(() => {
        const timeout = setTimeout(() => setDelayed(true), 0);
        return () => clearTimeout(timeout);
    }, []);

    return (
        <Card id={id}>
            <StyledCardHeader
                ref={headerRef}
                onDoubleClick={onDoubleClick}
                title={<Box className='widget-handler' sx={{
                    cursor: 'grab',
                    '&:active': {
                        cursor: 'grabbing',
                    }
                }}>{header}</Box>}
                action={
                    <Stack ref={actionRef} direction="row" spacing={1}>
                        {actions?.map((a: any, id: number) => <div key={id}>{a}</div>)}
                        <Tooltip title="Close widget" placement="top">
                            <IconButton onClick={() => dispatch(widgetActions.remove_widget({ gridId, id }))} size="medium">
                                <CancelIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                }
            />
            <Box sx={{ display: reduced ? 'none' : 'block' }}>
                <Divider />
                <CardContent style={{ padding: 0, height: height - headerHeight - 1, overflow: 'auto' }}>
                    {delayed &&
                        React.Children.map(children, child => {
                            if (child) return React.cloneElement(child, {
                                width,
                                height: height - headerHeight - 1,
                            });
                            return null;
                        })
                    }
                </CardContent>
            </Box>
        </Card>
    );
};